var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("fruity-page", {
        attrs: {
          "basket-quantity": _vm.$basketQuantity(_vm.bag),
          "banner-size": "full",
        },
        scopedSlots: _vm._u([
          {
            key: "hero-content",
            fn: function () {
              return [
                _c("div", { staticClass: "fruity-title" }, [
                  _vm._v("GIDDENS & THOMPSON"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "subtitle" }, [
                  _vm._v(
                    "\n          Purveyors of high quality Fruits and Vegetables with an emphasis on\n          providing seasonal, fresh and locally sourced produce. We also do\n          beautifully presented gift boxes and a range of Veg boxes which can be\n          delivered up to an 8 mile radius of Bungay.\n        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cta mt-4" },
                  [
                    _c(
                      "b-button",
                      { attrs: { variant: "secondary", href: "/shop" } },
                      [_vm._v("Shop Now for Deliveries & Collections")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "our-produce-and-about-us" }, [
                    _c(
                      "div",
                      { staticClass: "our-produce-and-featured-products" },
                      [
                        _c("div", { staticClass: "our-produce" }, [
                          _c("div", { staticClass: "fruity-title" }, [
                            _vm._v("Our Produce"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "product-cards" },
                            _vm._l(
                              _vm.chunk(
                                _vm.sortBy(_vm.ourProduce, "sort_order"),
                                2
                              ),
                              function (ourProduceCategoryChunk, idx) {
                                return _c(
                                  "div",
                                  {
                                    key: idx,
                                    staticClass: "product-cards-chunk",
                                  },
                                  [
                                    _vm._l(
                                      ourProduceCategoryChunk,
                                      function (ourProduceCategory) {
                                        return [
                                          _c("fruity-product-card", {
                                            key: ourProduceCategory.id,
                                            staticClass: "product-card",
                                            attrs: {
                                              href:
                                                "/shop?category=" +
                                                encodeURIComponent(
                                                  ourProduceCategory.name
                                                ),
                                              title: ourProduceCategory.name,
                                              "image-url":
                                                ourProduceCategory.image_url,
                                              "image-srcset":
                                                ourProduceCategory.image_srcset,
                                            },
                                          }),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "featured-products" }, [
                          _c("div", { staticClass: "fruity-title" }, [
                            _vm._v("Featured Products"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "product-cards" },
                            _vm._l(
                              _vm.chunk(
                                _vm.sortBy(_vm.featuredProducts, "sort_order"),
                                2
                              ),
                              function (featuredProductChunk, idx) {
                                return _c(
                                  "div",
                                  {
                                    key: idx,
                                    staticClass: "product-cards-chunk",
                                  },
                                  [
                                    _vm._l(
                                      featuredProductChunk,
                                      function (featuredProduct) {
                                        return [
                                          _c("fruity-product-card", {
                                            key: featuredProduct.id,
                                            staticClass: "product-card",
                                            attrs: {
                                              href:
                                                "/shop/" + featuredProduct.slug,
                                              title: featuredProduct.name,
                                              "image-url":
                                                featuredProduct.image_url,
                                              "image-srcset":
                                                featuredProduct.image_srcset,
                                            },
                                          }),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "why-choose-us" }, [
                      _c("div", { staticClass: "fruity-title" }, [
                        _vm._v("Using This Website"),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n                Thank you for visiting our website. You’ll find that it’s\n                different to most other online food shops because we don’t take\n                payment until a customer’s order is either collected or\n                delivered. The reason for this is because when dealing with\n                fresh produce, as we do, the wholesale prices can change daily.\n                If you place an order a few days or weeks in advance, the prices\n                listed on the day you place the order might not be the same as\n                when the order is put together. This could mean that you, our\n                customer, could be paying more than you need to if prices go\n                down and we, the retailer, could be losing money if the\n                wholesale price goes up. We could just err on the safe side for\n                us and charge a bit extra to cover any potential fluctuations,\n                but we don’t believe this is fair on our customers. So with our\n                system, the correct prices on the day your order is put\n                together, are automatically taken from the current prices on the\n                website that day.\n              "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "fruity-title" }, [
                        _vm._v("Payment"),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n                Another new element to this system is how we take payment. We\n                are using the iZettle service. Using this service allows us to\n                take mobile payments through our mobile phones. This way we can\n                pop outside the front of the shop with the card payment device\n                if you are collecting, or our driver can take payment if you are\n                having a delivery. If a customer is not at home when we deliver\n                then we can text or email a payment request instead.\n              "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "fruity-title" }, [
                        _vm._v("Why choose us?"),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n                We are a small independent business that has been supplying the\n                lovely people of Bungay and its surrounding areas with fresh and\n                great tasting fruit and vegetables for around six years. We are\n                very proud and happy to be nestled among a collective of other\n                wonderful independently owned shops, including four other food\n                businesses; a Deli, a Wholefood Shop, a Fresh Fish Shop and the\n                ‘Front Room’ cafe which sells freshly baked bread.\n              "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n                Our shop also sells pre-packed fresh meat from a local farm, so\n                Earsham Street really is a 'one-stop shop' destination if you're\n                looking for excellent quality produce from businesses that are\n                passionate about good service and good food.\n              "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n                You'll find us a very busy shop with a fast turnover of produce,\n                so you can be confident in the quality and freshness of our\n                fruit and vegetables. Customers are often surprised that\n                considering our size, we have a big selection of both usual and\n                unusual ingredients and if there's anything we don't have,\n                please ask and we'll always try and source it for you.\n              "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n                We hope you enjoy using our online shop, we've tried to make it\n                as easy as possible to use, but do give us a call if you need\n                help.If you like the idea of getting one of our regular 'veg\n                boxes', the contents of which will vary a little bit from week\n                to week, do call or email us to arrange. They are great for\n                people who love the creative surprise element of thinking \"oooh\n                what can I make with that!\".\n              "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n                Last but by no means least, if you're looking for the perfect\n                present for friends, family or colleagues who you know love\n                their fresh food, why not get them a beautifully presented gift\n                box? We also do gift certificates if you think they may prefer\n                to choose for themselves.\n              "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "feast-embed-container" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "https://www.feastnorfolkmagazine.co.uk/columnists/summer-fruits/",
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "feast-embed",
                              attrs: { src: _vm.feastEmbed },
                            }),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "contact-us", attrs: { id: "contact" } },
                    [
                      _c("div", { staticClass: "fruity-title" }, [
                        _vm._v("Contact Us"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "contact-details" }, [
                        _c("div", { staticClass: "contact-detail" }, [
                          _c("a", { attrs: { href: "tel:01986897944" } }, [
                            _vm._v("01986 897944"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "divider" }, [_vm._v("|")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "contact-detail" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "mailto:shop@giddensandthompson.co.uk",
                              },
                            },
                            [_vm._v("shop@giddensandthompson.co.uk")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "divider" }, [_vm._v("|")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "contact-detail" }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://www.giddensandthompson.co.uk",
                              },
                            },
                            [_vm._v("www.giddensandthompson.co.uk")]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "fruity-title mt-5" }, [
                        _vm._v("Social Media"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "social-icons" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href: "https://www.facebook.com/giddensandthompson",
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: {
                                href: "",
                                icon: ["fab", "facebook-square"],
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href: "https://twitter.com/GiddensThompson",
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fab", "twitter-square"] },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href: "https://www.instagram.com/giddensthompson/",
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["fab", "instagram-square"] },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "our-location", attrs: { id: "location" } },
                    [
                      _c("div", { staticClass: "fruity-title" }, [
                        _vm._v("Our Location"),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n              Feel free to visit our shop to view our extensive range of fruit\n              and vegetables.\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "Address: 36B Earsham Street, Bungay, Suffolk, NR35 1AQ"
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "map" },
                    [
                      _c(
                        "GmapMap",
                        {
                          staticClass: "google-maps",
                          attrs: {
                            center: { lat: 52.4565023, lng: 1.4326197 },
                            zoom: 16,
                            "map-type-id": "terrain",
                          },
                        },
                        [
                          _c("GmapMarker", {
                            attrs: {
                              position: { lat: 52.456536, lng: 1.434804 },
                              clickable: false,
                              draggable: false,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }