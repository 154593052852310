<template>
  <fruity-page>
    <template #hero-content
      ><div class="fruity-title">{{ title }}</div></template
    >
    <template #content>
      <div class="error-page mt-5 mb-5">
        {{ text }}
      </div>
    </template>
  </fruity-page>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    text: {
      required: true,
      type: String,
    },
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  components: {},
  created: function () {},
  mounted: function () {},
};
</script>

<style lang="scss" scoped>
.error-page {
  max-width: 800px;
  margin: auto;
  text-align: center;
}
</style>
