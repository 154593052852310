var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(_vm.componentType, { tag: "component" }, [
    _c(
      "div",
      { staticClass: "product-card" },
      [
        _c("ws-image", {
          attrs: {
            value: {
              image_url: _vm.imageUrl,
              image_srcset: _vm.imageSrcset,
            },
            width: 200,
            height: 200,
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }