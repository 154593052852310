<!-- This component allows a user to enter a weight (in grams, kilos, ounces,
  etc) but always outputs the weight in grams. -->
<template>
  <b-input-group class="input-weight flex-nowrap" @click="$refs.input.focus()">
    <b-form-input
      inputmode="numeric"
      class="min-w-60"
      v-model="inputValue"
      ref="input"
    />
    <b-input-group-append>
      <b-dropdown :text="selectedUnit.text" variant="primary">
        <b-dropdown-item
          v-for="unit in units"
          :key="unit.text"
          @click="selectedUnit = unit"
          >{{ unit.text }}</b-dropdown-item
        >
      </b-dropdown>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import {
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

export default {
  props: {
    weightGrams: {
      required: true,
      type: Number,
    },
    defaultUnit: {
      required: false,
      type: String,
      default: () => "kilos",
    },
  },
  data: function () {
    const units = [
      { text: "grams", weight_g: 1 },
      { text: "kilos", weight_g: 1000 },
      //{ text: "ounces", weight_g: 28.3495 },
      //{ text: "pounds", weight_g: 453.592 },
    ];

    let selectedUnit = units.find((unit) => unit.text === this.defaultUnit);
    if (selectedUnit === undefined) {
      selectedUnit = units.find((unit) => unit.text === "kilos");
    }

    const inputValue = this.weightGrams / selectedUnit.weight_g;

    return {
      inputValue, // Value as it is entered into the input field.
      units,
      selectedUnit,
    };
  },
  computed: {
    computedWeightGrams() {
      return this.selectedUnit.weight_g * parseFloat(this.inputValue);
    },
  },
  watch: {
    computedWeightGrams(newValue) {
      this.$emit("input", newValue);
    },
    selectedUnit(newValue) {
      // It is debatable whether we want to try something like this.
      // It seems inevitable that rounding errors will be introduced.
      // Additionally, if the user enters "500" meaning grams, then changes
      // from kilos to grams they will be annoyed.
      // this.inputValue = (this.weightGrams / newValue.weight_g).toFixed(3);
    },
  },
  methods: {},
  components: {
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
  },
  created: function () {},
  mounted: function () {},
};
</script>

<style lang="scss" scoped>
.min-w-60 {
  min-width: 60px;
}
</style>
