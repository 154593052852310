<template>
  <fruity-page :basket-quantity="$basketQuantity(bag)" banner-size="none">
    <template #hero-content><div class="fruity-title">Basket</div></template>

    <template #content>
      <div v-if="reorderNotification" class="notice mt-5">
        {{ reorderNotification }}
      </div>

      <div class="basket mt-5 mb-5" v-if="bag.length > 0">
        <div v-if="shouldShowFilters">
          <b-input-group>
            <b-form-input v-model="filterText" placeholder="Search..." />
            <b-form-select
              v-model="filterCategory"
              :options="categoryOptions"
            />
          </b-input-group>
        </div>

        <h3 class="mt-5 mb-3">Your Order</h3>
        <div class="bag" v-if="bag.length > 0">
          <fruity-bag v-model="filteredBag" :editable="true" />
        </div>
      </div>
      <div class="empty-bag mt-5 mb-5 text-center" v-else>
        <p>You don't have anything in your shopping basket.</p>
        <p>To add some items, click the link below.</p>
        <p>
          <b-button variant="secondary" href="/shop">Shop Now</b-button>
        </p>
      </div>

      <div class="d-flex justify-content-center mt-2 mb-3">
        <div class="action-button m-2">
          <b-button href="/shop" variant="primary" block
            >Continue shopping</b-button
          >
        </div>
        <div class="action-button m-2">
          <b-button href="/place-order" variant="secondary" block
            >Go to checkout</b-button
          >
        </div>
      </div>
    </template>
  </fruity-page>
</template>

<script>
import { BButton, BFormInput, BFormSelect, BInputGroup } from "bootstrap-vue";
import { chain, uniqBy } from "lodash";

export default {
  props: {
    initialBag: {
      required: true,
      type: Array,
    },
    productLines: {
      required: true,
      type: Array,
    },
    reorderNotification: {
      required: false,
      type: String,
      default: null,
    },
    shouldShowFilters: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      bag: this.initialBag.map((bagItem) => {
        const productLine = this.$find(this.productLines, {
          id: bagItem.product_line_id,
        });

        if (productLine === undefined) {
          throw new Error(
            `Could not find product line with id ${bagItem.product_line_id}`
          );
        }

        return {
          ...bagItem,
          product_line: {
            ...productLine,
            _name_lowercase: productLine.name.toLowerCase(),
          },
        };
      }),
      filterText: "",
      filterCategory: null,
    };
  },
  computed: {
    products() {
      return uniqBy(
        this.productLines.map((productLine) => productLine.product),
        "id"
      );
    },
    categoryOptions() {
      return [
        {
          text: "Select Category...",
          value: null,
        },
        ...chain(this.products)
          .map((product) => ({
            text: product.product_category_name,
            value: product.product_category_id,
          }))
          .uniqBy((option) => option.value)
          .sort()
          .value(),
      ];
    },
    filteredBag() {
      const lowercaseFilterText = this.filterText.toLowerCase();
      return this.bag.filter(
        (bagItem) =>
          (this.filterText === "" ||
            bagItem.product_line._name_lowercase.includes(
              lowercaseFilterText
            )) &&
          (this.filterCategory === null ||
            bagItem.product_line.product.product_category_id ===
              this.filterCategory)
      );
    },
  },
  watch: {},
  methods: {},
  components: {
    BButton,
    BFormSelect,
    BFormInput,
    BInputGroup,
  },
  created: function () {},
  mounted: function () {},
};
</script>

<style lang="scss" scoped>
@import "../_variables.scss";

.basket,
.empty-bag {
  max-width: 800px;
  margin: auto;

  & > * {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media only screen and (max-width: 500px) {
    & > .bag {
      /** Remove the container padding on small screens */
      padding-left: 0px;
      padding-right: 0px;
    }

    ::v-deep .table th,
    ::v-deep .table td {
      padding: 0.25rem;
    }
  }

  @media only screen and (max-width: 350px) {
    & > .bag {
      font-size: 14px;
    }
  }

  ::v-deep .image-cell {
    width: 120px;
  }

  .quantity-input-group {
    margin: auto;
    width: 130px;
  }
}

@media only screen and (min-width: 375px) {
  .action-button {
    width: 200px;
  }
}

.notice {
  text-align: center;
  text-size: 1.8em;
}
</style>
