<template>
  <div>
    <fruity-page :basket-quantity="$basketQuantity(bag)" banner-size="none">
      <template #hero-content
        ><div class="fruity-title">{{ product.name }}</div></template
      >

      <template #content>
        <div class="product">
          <div class="product-container image">
            <img :src="product.image_url" :srcset="product.image_srcset" />
          </div>

          <div class="product-container product-info">
            <div class="fruity-title">
              {{ product.name }}
            </div>
            <div class="description">
              {{ product.web_description }}
            </div>

            <div
              class="product-line"
              v-for="productLine in productLines"
              :key="productLine.id"
            >
              <div class="price-quantity">
                <div class="price">
                  {{ $friendlyFormatCurrency(productLine.price) }}
                  <small>{{ productLine.product_measure.name }}</small>
                </div>
              </div>

              <div class="product-line-quantity">
                <div class="product-line-input">
                  <fruity-product-quantity-input
                    :product-line="productLine"
                    v-model.number="productLine._dirty_quantity"
                    @input="
                      (newValue) => productLineChanged(productLine, newValue)
                    "
                  />
                </div>

                <div class="add-to-basket" outline>
                  <div
                    class="in-basket"
                    v-if="
                      bagItems[productLine.id] &&
                      bagItems[productLine.id].quantity > 0 &&
                      productLine._is_dirty === false
                    "
                    variant="in-basket"
                  >
                    in basket
                  </div>

                  <b-button
                    v-if="
                      productLine._is_dirty && productLine._dirty_quantity > 0
                    "
                    variant="outline-secondary"
                    @click="updateProductLine(productLine)"
                    block
                    >Add to basket</b-button
                  >
                  <b-button
                    class="text-nowrap"
                    v-if="
                      bagItems[productLine.id] &&
                      bagItems[productLine.id].quantity &&
                      productLine._is_dirty &&
                      productLine._dirty_quantity === 0
                    "
                    variant="outline-danger"
                    @click="updateProductLine(productLine)"
                    block
                    >Remove</b-button
                  >
                </div>
              </div>
            </div>

            <div class="mt-4">
              <b-button :href="continueShoppingUrl" variant="primary" block
                >Continue shopping</b-button
              >
            </div>
            <div class="mt-4">
              <b-button href="/place-order" variant="secondary" block
                >Go to checkout</b-button
              >
            </div>
          </div>
        </div>
      </template>
    </fruity-page>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";

export default {
  props: {
    product: {
      required: true,
      type: Object,
    },
    initialBag: {
      required: true,
      type: Array,
    },
  },
  data: function () {
    const bagItems = this.$keyBy(this.initialBag, "product_line_id");
    return {
      bag: this.$cloneDeep(this.initialBag),
      productLines: this.$sortBy(
        this.$cloneDeep(this.product.product_lines).map((productLine) => {
          // If it's not in the basket, show a sensible default.
          const bagProductLine = bagItems[productLine.id];

          // For units show 1, for kilos show 0.5.
          const defaultQuantity =
            productLine.product_measure.can_split === false ? 1 : 0.5;

          return {
            ...productLine,
            _is_dirty: bagProductLine ? false : true,
            _dirty_quantity: bagProductLine
              ? bagProductLine.quantity
              : defaultQuantity,
          };
        }),
        (productLine) => productLine.product_measure.weight_g
      ),
    };
  },
  computed: {
    bagItems() {
      return this.$keyBy(this.bag, "product_line_id");
    },
    continueShoppingUrl() {
      const urlParams = new URLSearchParams(window.location.search);
      const categoryParam = urlParams.get("category");

      if (categoryParam === null) {
        return "/shop";
      }

      return `/shop?category=${categoryParam}`;
    },
  },
  watch: {},
  methods: {
    // eslint-disable-next-line no-unused-vars
    productLineChanged(productLine, newValue) {
      productLine._is_dirty = true;
    },
    updateProductLine(productLine) {
      productLine._is_dirty = false;

      let bagItem = this.bagItems[productLine.id];
      if (bagItem === undefined) {
        bagItem = {
          product_line_id: productLine.id,
          quantity: productLine._dirty_quantity,
        };
        this.bag.push(bagItem);
      } else {
        bagItem.quantity = productLine._dirty_quantity;
      }

      this.$api.post(`/bag`, [bagItem]);

      if (productLine._dirty_quantity === 0) {
        this.bag = this.bag.filter((bagItem) => bagItem.quantity > 0);
      }
    },
  },
  components: { BButton },
  created: function () {},
  mounted: function () {},
};
</script>

<style lang="scss" scoped>
@import "../_variables.scss";

.product {
  margin-top: 1.5em;
  margin-bottom: 1.5em;

  display: flex;
  justify-content: center;

  .image {
    img {
      width: 100%;
    }
  }

  .product-container {
    flex: 1;
    margin: 2vw;
    max-width: 400px;
  }

  @media only screen and (min-width: 1040px) {
    .product-container {
      margin: 2em;
    }
  }

  .product-info {
    .price-quantity {
      display: flex;
      justify-content: space-between;

      .price {
        font-size: 1.5rem;
      }
    }

    .product-line {
      margin-top: 1em;
    }

    .add-to-cart {
      width: 100%;
    }

    .input-group-prepend > .input-group-text {
      justify-content: center;
      min-width: 150px;
    }
  }

  .product-line-quantity {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;

    > div {
      flex: 1;
      margin: 5px;
      min-width: 120px;
    }

    .in-basket {
      white-space: nowrap;
      padding-left: 3px;
      padding-right: 3px;
      background-color: #d7e9b0;
      border-color: #d7e9b0;
      display: block;
      width: 100%;
      text-align: center;
      vertical-align: middle;
      border: 1px solid transparent;
      border-radius: 0.25rem;
      line-height: 38px;
      height: 38px;
    }
  }
}
</style>
