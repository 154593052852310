<template>
  <div>
    <fruity-page :basket-quantity="$basketQuantity(bag)" banner-size="none">
      <template #hero-content><div class="gandt-title">Shop</div></template>
      <template #content>
        <fruity-products :products="products" class="mt-3 mb-3" />
      </template>
    </fruity-page>
  </div>
</template>

<script>
export default {
  props: {
    products: {
      required: true,
      type: Array,
    },
    bag: {
      required: true,
      type: Array,
    },
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  components: {},
  created: function () {},
  mounted: function () {},
};
</script>

<style lang="scss" scoped></style>
