var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("fruity-page", {
    attrs: {
      "basket-quantity": _vm.$basketQuantity(_vm.bag),
      "banner-size": "none",
    },
    scopedSlots: _vm._u([
      {
        key: "hero-content",
        fn: function () {
          return [
            _c("div", { staticClass: "gandt-title" }, [
              _vm._v("Privacy Policy"),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c("div", {
              staticClass: "privacy-policy",
              domProps: { innerHTML: _vm._s(_vm.privacyPolicyHtml) },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }