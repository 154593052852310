var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "product-quantity-input" },
    [
      _vm.productLine.product_measure.can_split === false
        ? [
            _vm.editable
              ? _c(
                  "b-input-group",
                  {
                    staticClass: "flex-nowrap quantity-input-group",
                    attrs: { block: "" },
                  },
                  [
                    _c(
                      "b-input-group-prepend",
                      [
                        _vm.editable
                          ? _c(
                              "b-button",
                              {
                                attrs: { variant: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.decrementProductLineQuantity(
                                      _vm.productLine.id
                                    )
                                  },
                                },
                              },
                              [_vm._v("-")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("b-form-input", {
                      staticClass: "unit-quantity-input",
                      attrs: { type: "text", inputmode: "numeric" },
                      model: {
                        value: _vm.productLineQuantity,
                        callback: function ($$v) {
                          _vm.productLineQuantity = _vm._n($$v)
                        },
                        expression: "productLineQuantity",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "b-input-group-append",
                      [
                        _vm.editable
                          ? _c(
                              "b-button",
                              {
                                attrs: { variant: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.incrementProductLineQuantity(
                                      _vm.productLine.id
                                    )
                                  },
                                },
                              },
                              [_vm._v("+")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c("div", [_vm._v(_vm._s(_vm.value))]),
          ]
        : [
            _vm.editable
              ? _c("fruity-input-weight", {
                  attrs: {
                    "weight-grams":
                      _vm.productLineQuantity *
                      _vm.productLine.product_measure.weight_g,
                  },
                  on: {
                    input: function (newWeight) {
                      return (_vm.productLineQuantity =
                        newWeight / _vm.productLine.product_measure.weight_g)
                    },
                  },
                })
              : _c("div", [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.formatProductMeasureQuantity(
                          _vm.productLine.product_measure,
                          _vm.value
                        )
                      ) +
                      "\n    "
                  ),
                ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }