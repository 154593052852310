<template>
  <div class="bag">
    <b-table
      primary-key="product_line_id"
      :items="notRemovedBagItems"
      :fields="fields"
      class="lt-align-cells-middle"
    >
      <template #cell(image)="data">
        <ws-image
          :value="data.item.product_line.product"
          :width="50"
          :height="50"
          style="border-radius: 5px"
        />
      </template>

      <template #cell(product_line.product.name)="data">
        <ws-image
          :value="data.item.product_line.product"
          :width="50"
          :height="50"
          style="border-radius: 5px"
          class="stacked-image"
        />

        <div>
          <!-- Consider whether to show the measure here. From a clean UX
                  perspective, it probably only makes sense to show it if the
                same item is in the basket twice with different measures. -->
          <a :href="`/shop/${data.item.product_line.product.slug}`">{{
            data.value
          }}</a>
        </div>
        <div>
          <small
            >{{ $friendlyFormatCurrency(data.item.product_line.price) }}
            {{ data.item.product_line.product_measure.name }}
          </small>
        </div>
      </template>

      <template #cell(quantity)="data">
        <!-- A wrapper div is required here or an unslightly double border
        appears just on this column header -->
        <div class="d-flex justify-content-center">
          <fruity-product-quantity-input
            :product-line="data.item.product_line"
            v-model="data.item.quantity"
            :editable="editable"
          />
        </div>
      </template>

      <template #cell(price)="data">
        {{
          $friendlyFormatCurrency(
            data.item.quantity * data.item.product_line.price
          )
        }}
      </template>

      <template #cell(_actions)="data">
        <b-button-close @click="removeBagItem(data.item)" />
      </template>
    </b-table>
  </div>
</template>

<script>
import { BButtonClose, BTable } from "bootstrap-vue";

export default {
  props: {
    value: {
      required: true,
      type: Array,
    },
    editable: {
      required: true,
      type: Boolean,
    },
  },
  data: function () {
    return {
      removedProductLineIds: [],
    };
  },
  computed: {
    fields() {
      return [
        {
          key: "image",
          label: "",
          class: "col-image",
        },
        {
          key: "product_line.product.name",
          class: "col-product-name",
          label: "Product",
        },
        {
          key: "quantity",
          class: "text-center",
        },
        {
          key: "price",
          label: "Price",
          class: "text-center col-price",
        },
        {
          key: "_actions",
          label: "",
          class: "text-center col-actions",
        },
      ];
    },
    bag() {
      return this.value.map((bagItem) => ({
        product_line_id: bagItem.product_line_id,
        quantity: bagItem.quantity,
      }));
    },
    notRemovedBagItems() {
      return this.value.filter(
        (bagItem) =>
          this.removedProductLineIds.includes(bagItem.product_line_id) === false
      );
    },
  },
  watch: {
    bag() {
      // Prevent another "request entity too large" error.
      // TODO: Do this in a less suprising way.
      this.$api.post(
        `/bag`,
        this.value.map((bagItem) => ({
          product_line_id: bagItem.product_line_id,
          quantity: bagItem.quantity,
        }))
      );
    },
  },
  methods: {
    removeBagItem(bagItem) {
      this.$set(bagItem, "quantity", 0);
      this.removedProductLineIds.push(bagItem.product_line_id);
    },
  },
  components: {
    BButtonClose,
    BTable,
  },
  created: function () {},
  mounted: function () {},
};
</script>

<style lang="scss" scoped>
.bag {
  img {
    min-width: 50px;
    max-height: 50px;
    width: auto;
    height: auto;
  }

  a {
    color: inherit;
  }

  ::v-deep .col-image {
  }

  ::v-deep .col-price {
    min-width: 80px;
  }

  ::v-deep .col-quantity {
  }

  ::v-deep .col-product-name {
    /* Long words must be split */
    overflow-wrap: anywhere;
  }

  ::v-deep table {
    tr {
      td {
        vertical-align: middle;
      }
    }
  }
}

/**
 * Below 375px wide the table starts to get cramped without changing the layout slightly.
 */
.stacked-image {
  display: none;
}

@media only screen and (max-width: 375px) {
  ::v-deep .col-image {
    display: none;
  }

  .stacked-image {
    display: block;
  }
}
</style>
