<template>
  <fruity-page>
    <template #hero-content
      ><div class="fruity-title">Thank You!</div></template
    >

    <template #content>
      <div class="thank-you mt-5 mb-5">
        <div class="order-confirmation">
          <div>Your order has been placed.</div>
          <div>For reference, your order number is</div>
          <div class="order-number">#{{ customerOrderId }}</div>
        </div>

        <div class="order-review">
          <div>
            <fruity-bag :value="bag" :editable="false" />
          </div>
          <div class="text-center">
            If you need to change your order or have any questions please call
            the shop on <a href="tel:01986897944">01986 897944</a>.
          </div>
        </div>
      </div>
    </template>
  </fruity-page>
</template>

<script>
export default {
  props: {
    customerOrderId: {
      required: true,
      type: Number,
    },
    bag: {
      required: true,
      type: Array,
    },
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  components: {},
  created: function () {},
  mounted: function () {},
};
</script>

<style lang="scss" scoped>
.thank-you {
  max-width: 800px;
  margin: auto;

  .order-confirmation {
    font-size: 2em;
    text-align: center;

    .order-number {
      font-size: 2.5em;
      color: #777;
    }
  }

  a {
    color: black;
  }
}
</style>
