var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-input-group",
    {
      staticClass: "input-weight flex-nowrap",
      on: {
        click: function ($event) {
          return _vm.$refs.input.focus()
        },
      },
    },
    [
      _c("b-form-input", {
        ref: "input",
        staticClass: "min-w-60",
        attrs: { inputmode: "numeric" },
        model: {
          value: _vm.inputValue,
          callback: function ($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue",
        },
      }),
      _vm._v(" "),
      _c(
        "b-input-group-append",
        [
          _c(
            "b-dropdown",
            { attrs: { text: _vm.selectedUnit.text, variant: "primary" } },
            _vm._l(_vm.units, function (unit) {
              return _c(
                "b-dropdown-item",
                {
                  key: unit.text,
                  on: {
                    click: function ($event) {
                      _vm.selectedUnit = unit
                    },
                  },
                },
                [_vm._v(_vm._s(unit.text))]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }