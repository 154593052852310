var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("fruity-page", {
        attrs: {
          "basket-quantity": _vm.$basketQuantity(_vm.bag),
          "banner-size": "none",
        },
        scopedSlots: _vm._u([
          {
            key: "hero-content",
            fn: function () {
              return [
                _c("div", { staticClass: "fruity-title" }, [
                  _vm._v(_vm._s(_vm.product.name)),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticClass: "product" }, [
                  _c("div", { staticClass: "product-container image" }, [
                    _c("img", {
                      attrs: {
                        src: _vm.product.image_url,
                        srcset: _vm.product.image_srcset,
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "product-container product-info" },
                    [
                      _c("div", { staticClass: "fruity-title" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.product.name) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.product.web_description) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.productLines, function (productLine) {
                        return _c(
                          "div",
                          { key: productLine.id, staticClass: "product-line" },
                          [
                            _c("div", { staticClass: "price-quantity" }, [
                              _c("div", { staticClass: "price" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$friendlyFormatCurrency(
                                        productLine.price
                                      )
                                    ) +
                                    "\n                "
                                ),
                                _c("small", [
                                  _vm._v(
                                    _vm._s(productLine.product_measure.name)
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "product-line-quantity" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "product-line-input" },
                                  [
                                    _c("fruity-product-quantity-input", {
                                      attrs: { "product-line": productLine },
                                      on: {
                                        input: function (newValue) {
                                          return _vm.productLineChanged(
                                            productLine,
                                            newValue
                                          )
                                        },
                                      },
                                      model: {
                                        value: productLine._dirty_quantity,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            productLine,
                                            "_dirty_quantity",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression:
                                          "productLine._dirty_quantity",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "add-to-basket",
                                    attrs: { outline: "" },
                                  },
                                  [
                                    _vm.bagItems[productLine.id] &&
                                    _vm.bagItems[productLine.id].quantity > 0 &&
                                    productLine._is_dirty === false
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "in-basket",
                                            attrs: { variant: "in-basket" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  in basket\n                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    productLine._is_dirty &&
                                    productLine._dirty_quantity > 0
                                      ? _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "outline-secondary",
                                              block: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.updateProductLine(
                                                  productLine
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Add to basket")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.bagItems[productLine.id] &&
                                    _vm.bagItems[productLine.id].quantity &&
                                    productLine._is_dirty &&
                                    productLine._dirty_quantity === 0
                                      ? _c(
                                          "b-button",
                                          {
                                            staticClass: "text-nowrap",
                                            attrs: {
                                              variant: "outline-danger",
                                              block: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.updateProductLine(
                                                  productLine
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Remove")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt-4" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                href: _vm.continueShoppingUrl,
                                variant: "primary",
                                block: "",
                              },
                            },
                            [_vm._v("Continue shopping")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt-4" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                href: "/place-order",
                                variant: "secondary",
                                block: "",
                              },
                            },
                            [_vm._v("Go to checkout")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }