<template>
  <div class="products">
    <b-container>
      <b-input-group>
        <b-form-input v-model="filterText" placeholder="Search..." />
        <b-form-select v-model="filterCategory" :options="categoryOptions" />
      </b-input-group>

      <div class="mt-1 mb-1 text-center" v-if="filterTitle !== null">
        <h1>{{ filterTitle }}</h1>
      </div>

      <div class="product-cards">
        <template v-for="product in filteredProducts">
          <fruity-product-card
            :key="product.id"
            :href="productUrl(product)"
            :title="product.name"
            :image-url="product.image_url"
            :image-srcset="product.image_srcset"
          />
        </template>
      </div>
    </b-container>
  </div>
</template>

<script>
import {
  BContainer,
  BInputGroup,
  BFormInput,
  BFormSelect,
} from "bootstrap-vue";
import { chain } from "lodash";

export default {
  props: {
    products: {
      required: true,
      type: Array,
    },
  },
  data: function () {
    return {
      filterText: "",
      filterCategory: null,
    };
  },
  computed: {
    filteredProducts() {
      const filterText = this.filterText.toLowerCase();

      // Simon wants it so that when there is text entered, it searches all products.
      if (filterText !== "") {
        return this.products.filter((p) =>
          p.name.toLowerCase().includes(filterText)
        );
      }

      return this.products.filter(
        (p) =>
          this.filterCategory === null ||
          this.filterCategory === p.product_category_name
      );
    },
    categoryOptions() {
      return [
        {
          text: "Select Category...",
          value: null,
        },
        ...chain(this.products)
          .map((p) => ({
            text: p.product_category_name,
            value: p.product_category_name,
          }))
          .uniqBy((option) => option.value)
          .sortBy("value")
          .value(),
      ];
    },
    filterTitle() {
      if (this.filterText !== "") {
        return "Search results";
      }

      if (this.filterCategory !== "") {
        return this.filterCategory;
      }

      return null;
    },
  },
  watch: {
    filterCategory(newValue) {
      history.replaceState("", "", `/shop?category=${newValue}`);
    },
  },
  methods: {
    productUrl(product) {
      if (this.filterCategory === null) {
        return `/shop/${product.slug}`;
      }

      // Include the category so it can be returned to easily.
      return `/shop/${product.slug}?category=${this.filterCategory}`;
    },
  },
  components: { BContainer, BInputGroup, BFormInput, BFormSelect },
  created: function () {
    const urlParams = new URLSearchParams(window.location.search);
    const categoryParam = urlParams.get("category");
    if (
      this.categoryOptions.find(
        (categoryOption) => categoryOption.value === categoryParam
      )
    ) {
      this.filterCategory = categoryParam;
    }
  },
  mounted: function () {},
};
</script>

<style lang="scss" scoped>
.products {
  .product-cards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
</style>
