var render = function () {
  var _obj, _obj$1, _obj$2
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "header" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "contact-details" }, [
        _c("div", { staticClass: "contact-detail" }, [
          _c(
            "a",
            { attrs: { href: "tel:01986897944" } },
            [
              _c("b-icon-telephone", { staticClass: "mr-1" }),
              _c("span", { staticClass: "d-none d-lg-inline-block" }, [
                _vm._v("01986 897944"),
              ]),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "contact-detail" }, [
          _c(
            "a",
            { attrs: { href: "https://g.page/giddensandthompson?share" } },
            [
              _c("b-icon-geo", { staticClass: "mr-1" }),
              _c("span", { staticClass: "d-none d-lg-inline-block" }, [
                _vm._v("Get directions"),
              ]),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "contact-detail" }, [
          _c(
            "a",
            { attrs: { href: "#opening-hours" } },
            [
              _c("b-icon-clock", { staticClass: "mr-1" }),
              _c("span", { staticClass: "d-none d-lg-inline-block" }, [
                _vm._v("Opening hours"),
              ]),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.basketQuantity > 0,
                expression: "basketQuantity > 0",
              },
            ],
            staticClass: "contact-detail",
          },
          [
            _c(
              "b-button",
              { attrs: { href: "/basket", size: "sm", variant: "basket" } },
              [
                _c("b-icon-basket-3", { staticClass: "mr-1" }),
                _vm._v(" "),
                _c(
                  "transition",
                  { attrs: { name: "slide-fade", mode: "out-in" } },
                  [
                    _c(
                      "div",
                      {
                        key: _vm.basketQuantity,
                        staticClass: "basket-quantity",
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.basketQuantity) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "header-spacer" }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "banner",
        class:
          ((_obj = {}),
          (_obj["d-none"] = _vm.bannerSize === "none"),
          (_obj["banner-small"] = _vm.bannerSize === "small"),
          (_obj["banner-normal"] = _vm.bannerSize === "normal"),
          _obj),
      },
      [
        _c("div", { staticClass: "banner-image" }),
        _vm._v(" "),
        _c("div", { staticClass: "banner-overlay" }),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "hero" }, [
      _c("a", { attrs: { href: "/" } }, [
        _c("div", {
          staticClass: "logo",
          class:
            ((_obj$1 = {}),
            (_obj$1["banner-hidden"] = _vm.bannerSize === "none"),
            _obj$1),
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "hero-content",
          class:
            ((_obj$2 = {}),
            (_obj$2["d-none"] = _vm.bannerSize === "none"),
            _obj$2),
        },
        [_vm._t("hero-content")],
        2
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [_vm._t("content")], 2),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "site-map" },
      [
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { staticClass: "section text-left", attrs: { sm: "auto" } },
              [
                _c(
                  "div",
                  { staticClass: "heading", attrs: { id: "opening-hours" } },
                  [_vm._v("OPENING HOURS")]
                ),
                _vm._v(" "),
                _c("table", { staticClass: "site-map-table" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [_vm._v("Monday")]),
                      _vm._v(" "),
                      _c("td", [_vm._v("9:00 hrs ― 17:00 hrs")]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Tuesday")]),
                      _vm._v(" "),
                      _c("td", [_vm._v("9:00 hrs ― 17:00 hrs")]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Wednesday")]),
                      _vm._v(" "),
                      _c("td", [_vm._v("9:00 hrs ― 17:00 hrs")]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Thursday")]),
                      _vm._v(" "),
                      _c("td", [_vm._v("9:00 hrs ― 17:00 hrs")]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Friday")]),
                      _vm._v(" "),
                      _c("td", [_vm._v("9:00 hrs ― 17:00 hrs")]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Saturday")]),
                      _vm._v(" "),
                      _c("td", [_vm._v("9:00 hrs ― 17:00 hrs")]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Sunday")]),
                      _vm._v(" "),
                      _c("td", [_vm._v("CLOSED")]),
                    ]),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "b-col",
              { staticClass: "section text-left", attrs: { sm: "auto" } },
              [
                _c(
                  "div",
                  { staticClass: "heading", attrs: { id: "opening-hours" } },
                  [
                    _vm._v(
                      "\n          OPENING HOURS (DURING CHRISTMAS)\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("table", { staticClass: "site-map-table" }, [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [
                        _vm._v("Monday 23"),
                        _c("sup", [_vm._v("rd")]),
                        _vm._v(" Dec"),
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v("9:00 hrs ― 18:00 hrs")]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v("Tuesday 24"),
                        _c("sup", [_vm._v("th")]),
                        _vm._v(" Dec (Christmas Eve)"),
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v("9:00 hrs ― 14:00 hrs")]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v("Wednesday 25"),
                        _c("sup", [_vm._v("th")]),
                        _vm._v(" Dec (Christmas Day)"),
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v("CLOSED")]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v("Thursday 26"),
                        _c("sup", [_vm._v("th")]),
                        _vm._v(" Dec (Boxing Day)"),
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v("CLOSED")]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v("Friday 27"),
                        _c("sup", [_vm._v("th")]),
                        _vm._v(" Dec"),
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v("10:00 hrs ― 17:00 hrs")]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v("Saturday 28"),
                        _c("sup", [_vm._v("th")]),
                        _vm._v(" Dec"),
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v("10:00 hrs ― 17:00 hrs")]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v("Sunday 29"),
                        _c("sup", [_vm._v("th")]),
                        _vm._v(" Dec"),
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v("CLOSED")]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v("Monday 30"),
                        _c("sup", [_vm._v("th")]),
                        _vm._v(" Dec"),
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v("10:00 hrs ― 16:00 hrs")]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v("Tuesday 31"),
                        _c("sup", [_vm._v("st")]),
                        _vm._v(" Dec (New Year's Eve)"),
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v("10:00 hrs ― 14:00 hrs")]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v("Wednesday 1st Jan 2025 (New Year's Day)"),
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v("CLOSED")]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Thursday 2nd Jan 2025")]),
                      _vm._v(" "),
                      _c("td", [_c("i", [_vm._v("Regular hours resume")])]),
                    ]),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "b-col",
              { staticClass: "col text-left", attrs: { sm: "auto" } },
              [
                _c("div", { staticClass: "section" }, [_vm._v("CONTACT US")]),
                _vm._v(" "),
                _c("table", { staticClass: "site-map-table" }, [
                  _c("tbody", [
                    _c("tr", [_c("td", [_vm._v("36B Earsham Street")])]),
                    _vm._v(" "),
                    _c("tr", [_c("td", [_vm._v("Bungay")])]),
                    _vm._v(" "),
                    _c("tr", [_c("td", [_vm._v("Suffolk")])]),
                    _vm._v(" "),
                    _c("tr", [_c("td", [_vm._v("NR35 1AQ")])]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _c(
                          "a",
                          { attrs: { href: "tel:01986897944" } },
                          [
                            _c("b-icon-telephone", { staticClass: "mr-1" }),
                            _c("span", [_vm._v("01986 897944")]),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "mailto:shop@giddensandthompson.co.uk",
                            },
                          },
                          [_vm._v("shop@giddensandthompson.co.uk")]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _c("a", { attrs: { href: "/privacy-policy" } }, [
                          _vm._v("Privacy policy"),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "links" }, [
      _c("div", { staticClass: "link" }, [
        _c("a", { attrs: { href: "/shop" } }, [_vm._v("Shop")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "link hide-on-very-small-screens" }, [
        _c("a", { attrs: { href: "/#location" } }, [_vm._v("Location")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "link" }, [
        _c("a", { attrs: { href: "/#contact" } }, [_vm._v("Contact Us")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "link" }, [
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              href: "https://www.google.com/maps/d/viewer?mid=1QIvTedNP39GWsgwvCVbDrtV8sZMYfSLY&ll=52.466780397135715%2C1.4232594000000098&z=11",
            },
          },
          [_vm._v("Delivery Map")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }