<template>
  <div>
    <fruity-page :basket-quantity="$basketQuantity(bag)" banner-size="full">
      <template #hero-content>
        <div class="fruity-title">GIDDENS &amp; THOMPSON</div>
        <div class="subtitle">
          Purveyors of high quality Fruits and Vegetables with an emphasis on
          providing seasonal, fresh and locally sourced produce. We also do
          beautifully presented gift boxes and a range of Veg boxes which can be
          delivered up to an 8 mile radius of Bungay.
        </div>
        <div class="cta mt-4">
          <b-button variant="secondary" href="/shop"
            >Shop Now for Deliveries &amp; Collections</b-button
          >
        </div>
      </template>

      <template #content>
        <div class="content">
          <div class="christmas-banner mx-3">
            <div class="my-3"><b>Christmas Collection and Delivery Slots now open!</b></div>

            <b-button
              class="my-3"
              variant="outline-danger"
              href="/static/fruity/Christmas%20Order%20Form%202024.pdf"
            >
              Click here to download our Christmas order form!
            </b-button>
          </div>
          <div class="our-produce-and-about-us">
            <div class="our-produce-and-featured-products">
              <div class="our-produce">
                <div class="fruity-title">Our Produce</div>

                <div class="product-cards">
                  <div
                    class="product-cards-chunk"
                    v-for="(ourProduceCategoryChunk, idx) in chunk(
                      sortBy(ourProduce, 'sort_order'),
                      2
                    )"
                    :key="idx"
                  >
                    <template
                      v-for="ourProduceCategory in ourProduceCategoryChunk"
                    >
                      <fruity-product-card
                        class="product-card"
                        :key="ourProduceCategory.id"
                        :href="`/shop?category=${encodeURIComponent(
                          ourProduceCategory.name
                        )}`"
                        :title="ourProduceCategory.name"
                        :image-url="ourProduceCategory.image_url"
                        :image-srcset="ourProduceCategory.image_srcset"
                      />
                    </template>
                  </div>
                </div>
              </div>

              <div class="featured-products">
                <div class="fruity-title">Featured Products</div>

                <div class="product-cards">
                  <div
                    class="product-cards-chunk"
                    v-for="(featuredProductChunk, idx) in chunk(
                      sortBy(featuredProducts, 'sort_order'),
                      2
                    )"
                    :key="idx"
                  >
                    <template v-for="featuredProduct in featuredProductChunk">
                      <fruity-product-card
                        class="product-card"
                        :href="`/shop/${featuredProduct.slug}`"
                        :key="featuredProduct.id"
                        :title="featuredProduct.name"
                        :image-url="featuredProduct.image_url"
                        :image-srcset="featuredProduct.image_srcset"
                      />
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <div class="why-choose-us">
              <!-- Removed but saved in case we need this kind of thing again. -->
              <!--
              <div class="fruity-title">Temporarily Closed</div>
              <p><img class="sorry-closed" :src="sorryClosed" /></p>
              <p>
                So sorry about this folks but we’ve had someone in the family
                test positive for Covid and while it’s extremely unlikely that
                any of us have caught it because any contact was minimal (in
                fact it was outside of the 48hr period of infectiousness till
                symptoms started), it would still be irresponsible of me to not
                take all the precautions necessary.
              </p>
              <p>
                Assuming we are ok over the next few days, the shop will reopen
                again on Tuesday 5th January. Weekly Collections and Deliveries
                will also hopefully resume from the 5th.
              </p>
              <p>
                Apologies for any inconvenience, but it’s better to be safe than
                sorry.
              </p>
              <p>Simon</p>
              -->

              <div class="fruity-title">Using This Website</div>
              <p>
                Thank you for visiting our website. You’ll find that it’s
                different to most other online food shops because we don’t take
                payment until a customer’s order is either collected or
                delivered. The reason for this is because when dealing with
                fresh produce, as we do, the wholesale prices can change daily.
                If you place an order a few days or weeks in advance, the prices
                listed on the day you place the order might not be the same as
                when the order is put together. This could mean that you, our
                customer, could be paying more than you need to if prices go
                down and we, the retailer, could be losing money if the
                wholesale price goes up. We could just err on the safe side for
                us and charge a bit extra to cover any potential fluctuations,
                but we don’t believe this is fair on our customers. So with our
                system, the correct prices on the day your order is put
                together, are automatically taken from the current prices on the
                website that day.
              </p>
              <div class="fruity-title">Payment</div>
              <p>
                Another new element to this system is how we take payment. We
                are using the iZettle service. Using this service allows us to
                take mobile payments through our mobile phones. This way we can
                pop outside the front of the shop with the card payment device
                if you are collecting, or our driver can take payment if you are
                having a delivery. If a customer is not at home when we deliver
                then we can text or email a payment request instead.
              </p>
              <div class="fruity-title">Why choose us?</div>
              <p>
                We are a small independent business that has been supplying the
                lovely people of Bungay and its surrounding areas with fresh and
                great tasting fruit and vegetables for around six years. We are
                very proud and happy to be nestled among a collective of other
                wonderful independently owned shops, including four other food
                businesses; a Deli, a Wholefood Shop, a Fresh Fish Shop and the
                ‘Front Room’ cafe which sells freshly baked bread.
              </p>
              <p>
                Our shop also sells pre-packed fresh meat from a local farm, so
                Earsham Street really is a 'one-stop shop' destination if you're
                looking for excellent quality produce from businesses that are
                passionate about good service and good food.
              </p>
              <p>
                You'll find us a very busy shop with a fast turnover of produce,
                so you can be confident in the quality and freshness of our
                fruit and vegetables. Customers are often surprised that
                considering our size, we have a big selection of both usual and
                unusual ingredients and if there's anything we don't have,
                please ask and we'll always try and source it for you.
              </p>
              <p>
                We hope you enjoy using our online shop, we've tried to make it
                as easy as possible to use, but do give us a call if you need
                help.If you like the idea of getting one of our regular 'veg
                boxes', the contents of which will vary a little bit from week
                to week, do call or email us to arrange. They are great for
                people who love the creative surprise element of thinking "oooh
                what can I make with that!".
              </p>
              <p>
                Last but by no means least, if you're looking for the perfect
                present for friends, family or colleagues who you know love
                their fresh food, why not get them a beautifully presented gift
                box? We also do gift certificates if you think they may prefer
                to choose for themselves.
              </p>
              <div class="feast-embed-container">
                <a
                  href="https://www.feastnorfolkmagazine.co.uk/columnists/summer-fruits/"
                >
                  <img class="feast-embed" :src="feastEmbed" />
                </a>
              </div>
            </div>
          </div>

          <div class="contact-us" id="contact">
            <div class="fruity-title">Contact Us</div>
            <div class="contact-details">
              <div class="contact-detail">
                <a href="tel:01986897944">01986 897944</a>
              </div>
              <span class="divider">|</span>
              <div class="contact-detail">
                <a href="mailto:shop@giddensandthompson.co.uk"
                  >shop@giddensandthompson.co.uk</a
                >
              </div>
              <span class="divider">|</span>
              <div class="contact-detail">
                <a href="https://www.giddensandthompson.co.uk"
                  >www.giddensandthompson.co.uk</a
                >
              </div>
            </div>

            <div class="fruity-title mt-5">Social Media</div>
            <div class="social-icons">
              <a
                target="_blank"
                href="https://www.facebook.com/giddensandthompson"
              >
                <font-awesome-icon href="" :icon="['fab', 'facebook-square']" />
              </a>
              <a target="_blank" href="https://twitter.com/GiddensThompson">
                <font-awesome-icon :icon="['fab', 'twitter-square']" />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/giddensthompson/"
              >
                <font-awesome-icon :icon="['fab', 'instagram-square']" />
              </a>
            </div>
          </div>

          <div class="our-location" id="location">
            <div class="fruity-title">Our Location</div>
            <p>
              Feel free to visit our shop to view our extensive range of fruit
              and vegetables.
            </p>
            <p>Address: 36B Earsham Street, Bungay, Suffolk, NR35 1AQ</p>
          </div>

          <div class="map">
            <GmapMap
              :center="{ lat: 52.4565023, lng: 1.4326197 }"
              :zoom="16"
              map-type-id="terrain"
              class="google-maps"
            >
              <GmapMarker
                :position="{ lat: 52.456536, lng: 1.434804 }"
                :clickable="false"
                :draggable="false"
              />
            </GmapMap>
          </div>
        </div>
      </template>
    </fruity-page>
  </div>
</template>

<script>
import { chunk, sortBy } from "lodash";
import { BButton } from "bootstrap-vue";
import feastEmbed from "./../img/feast-embed-sm.jpg";
import sorryClosed from "./../img/fruity-sorry-closed.png";

export default {
  props: {
    ourProduce: {
      required: true,
      type: Array,
    },
    featuredProducts: {
      required: true,
      type: Array,
    },
    bag: {
      required: true,
      type: Array,
    },
  },
  data: function () {
    return {};
  },
  computed: {
    feastEmbed() {
      return feastEmbed;
    },
    sorryClosed: () => sorryClosed,
  },
  watch: {},
  methods: {
    sortBy,
    chunk,
    scrollIfRequired(selector) {
      // Workaround for Vue not being loaded when page loads.
      if (document.location.hash === selector) {
        setTimeout(() => {
          document
            .querySelector(selector)
            .scrollIntoView({ behavior: "smooth", block: "start" });
        }, 300);
      }
    },
  },
  components: {
    BButton,
  },
  created: function () {
    this.scrollIfRequired("#contact");
    this.scrollIfRequired("#location");
  },
  mounted: function () {},
};
</script>

<style lang="scss" scoped>
@import "../_variables.scss";

.content {
  .christmas-banner {
    margin: 0 auto;
    text-align: center;
  }

  .our-produce-and-about-us {
    margin: 0 auto;

    display: flex;
    flex-direction: row;

    max-width: 984px;

    .why-choose-us {
      flex: 2;
      margin-left: 1em;
    }

    .our-produce-and-featured-products {
      flex: 2;
    }

    .product-cards-chunk {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .our-produce,
    .featured-products {
      display: flex;
      flex-direction: column;
      align-items: center;

      .product-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    .fruity-title {
      margin-top: 1em;
    }
  }

  .our-location {
    @extend %centered-section;
    background-color: white;
  }

  /**
   * This is the snap point at which it's not possible to fit 2 product images
   * in the left half of the text, so it looks better when it goes to a vertical
   * layout.
   */
  @media only screen and (max-width: 1040px) {
    /* Use Vertical Layout */
    .our-produce-and-about-us {
      flex-direction: column;

      .why-choose-us {
        flex: 1;
        margin-right: 1em;
      }

      .our-produce-and-featured-products {
        flex: 1;
      }
    }
  }
}

.contact-us {
  @extend %centered-section;

  .social-icons {
    font-size: 2em;
    color: darken($fruity-yellow, 30%);

    a {
      color: darken($fruity-yellow, 30%);
    }
  }

  .contact-details {
    .contact-detail {
      display: inline-block;
    }

    @media only screen and (max-width: 680px) {
      .contact-detail {
        display: block;
      }

      .divider {
        display: none;
      }
    }
  }

  a {
    color: black;
  }
}

.map {
  .google-maps {
    width: 100%;
    height: 500px;
  }

  ::v-deep .vue-map-container .vue-map {
    width: 100%;
    height: 100%;
  }
}

.feast-embed-container {
  display: flex;
  justify-content: flex-end;

  .feast-embed {
    max-width: 200px;
    text-align: right;
  }
}

.sorry-closed {
  max-width: 100%;
  width: 300px;
}
</style>
