<!-- This component allows the user to enter a product line quantity.
  If the product line is measured in units, it will show plus and minus arrows.
  If the product line is measured in weight_g, it will show an input with various weight options.
  The output from this component is a product line quantity.

  When entering a weight, this component must convert that to a product line
  quantity by dividing by the product measure's weight_g.

  TODO: Update to support non-fractional product measures.
  TODO: Update to allow a default unit for weight entry.
-->
<template>
  <div class="product-quantity-input">
    <template v-if="productLine.product_measure.can_split === false">
      <b-input-group
        class="flex-nowrap quantity-input-group"
        v-if="editable"
        block
      >
        <b-input-group-prepend>
          <b-button
            v-if="editable"
            variant="primary"
            @click="decrementProductLineQuantity(productLine.id)"
            >-</b-button
          >
        </b-input-group-prepend>
        <b-form-input
          type="text"
          class="unit-quantity-input"
          inputmode="numeric"
          v-model.number="productLineQuantity"
        />
        <b-input-group-append>
          <b-button
            v-if="editable"
            variant="primary"
            @click="incrementProductLineQuantity(productLine.id)"
            >+</b-button
          >
        </b-input-group-append>
      </b-input-group>
      <div v-else>{{ value }}</div>
    </template>
    <template v-else>
      <!-- TODO: This assumes that when can_split is true, it's measured in weight.
        TODO:  Support fractional volume and unit measures. -->
      <fruity-input-weight
        :weight-grams="
          productLineQuantity * productLine.product_measure.weight_g
        "
        @input="
          (newWeight) =>
            (productLineQuantity =
              newWeight / productLine.product_measure.weight_g)
        "
        v-if="editable"
      />
      <div v-else>
        {{ formatProductMeasureQuantity(productLine.product_measure, value) }}
      </div>
    </template>
  </div>
</template>

<script>
import {
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
} from "bootstrap-vue";

export default {
  props: {
    productLine: {
      required: true,
      type: Object,
    },
    value: {
      required: true,
      type: Number,
    },
    editable: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      productLineQuantity: this.value,
    };
  },
  computed: {
    intValue() {
      return parseInt(this.value);
    },
  },
  watch: {
    productLineQuantity(newValue) {
      this.$emit("input", newValue);
    },
  },
  methods: {
    // Format a quantity as a given fraction of a productMeasure quantity in a
    // human readable way.
    // TODO: This is shared with Omni Tend.
    // TODO: Unify this behavour with Omni Tend in a shared library.
    formatProductMeasureQuantity(productMeasure, quantity) {
      if (productMeasure.measured_using === "weight_g") {
        return `${quantity * productMeasure.weight_g} grams`;
      }

      // TODO: Test / think about this. Why is the unit used here but not when
      // can_spit is false?
      if (productMeasure.measured_using === "units") {
        return `${quantity} ${this.productMeasure.web_label}`;
      }
    },
    changeProductLineQuantity(productLineId, quantity) {
      this.productLineQuantity += quantity;
    },
    decrementProductLineQuantity(productLineId) {
      if (this.productLineQuantity > 0) {
        this.changeProductLineQuantity(productLineId, -1);
      }
    },
    incrementProductLineQuantity(productLineId, quantity) {
      this.changeProductLineQuantity(productLineId, 1);
    },
  },
  components: {
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
  },
  created: function () {},
  mounted: function () {},
};
</script>

<style lang="scss" scoped>
.product-quantity-input {
  /** Specifying a max width here since it looks silly when this is very wide.
  * But it might be more sensible for the parent element to set a width.
  */
  max-width: 175px;
}

.unit-quantity-input {
  min-width: 44px;
  text-align: center;
}
</style>
