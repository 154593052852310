<template>
  <component :is="componentType">
    <div class="product-card">
      <ws-image
        :value="{
          image_url: imageUrl,
          image_srcset: imageSrcset,
        }"
        :width="200"
        :height="200"
      />
      <div class="title">{{ title }}</div>
    </div>
  </component>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    imageUrl: {
      required: true,
      type: String,
    },
    imageSrcset: {
      required: false,
      type: String,
      default: null,
    },
  },
  data: function () {
    return {};
  },
  computed: {
    componentType() {
      if (this.$attrs.href !== undefined) {
        return "a";
      }

      return "div";
    },
  },
  watch: {},
  methods: {},
  components: {},
  created: function () {},
  mounted: function () {},
};
</script>

<style lang="scss" scoped>
@import "../_variables.scss";

a .product-card {
  color: initial;
}

.product-card {
  margin: 10px;

  .title {
    /**
    * This version wraps the text and creates a larger bar at the bottom.
    *
    * Alternatively, the bar can be a fixed height, but it means the text length
    * must be limited.
    **/
    width: 100%;
    min-width: 100%;
    max-width: 200px;
    background-color: $fruity-yellow;
    text-align: center;
    font-size: 0.8em;
    font-weight: 500;
    line-height: 18px;
    padding-bottom: 5px;
    padding-top: 5px;
  }
}
</style>
