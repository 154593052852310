// Base JavaScript for the "fruity" theme.
// TODO: There is probably client-specific things in here that need to be
// removed, e.g. the Google Maps API key.
import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTwitterSquare,
  faFacebookSquare,
  faLinkedin,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "./fruity.scss";
import * as VueGoogleMaps from "vue2-google-maps";
import Helpers from "./helpers.js";

import Api from "@/plugins/api";
import Lodash from "@/plugins/lodash";
import CurrencyPlugin from "@/plugins/currency";
import ReportErrorPlugin from "@/plugins/report-error";

Vue.use(Helpers);
Vue.use(Api);
Vue.use(Lodash);
Vue.use(CurrencyPlugin);
Vue.use(ReportErrorPlugin);

library.add(faTwitterSquare);
library.add(faFacebookSquare);
library.add(faLinkedin);
library.add(faInstagramSquare);
Vue.component("FontAwesomeIcon", FontAwesomeIcon);

// TODO: Do not hard code Google Maps API key.
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyC6OZiV7th-C7hNgBoD0WD8CisYqfLG2pQ",
  },
});

Vue.config.errorHandler = function (error) {
  if (window.onerror) {
    window.onerror(error.message, "", "", "", error.stack);
  }
  throw error;
};

// Generic components.
const files = require.context("../generic/components", true, /\.vue$/i);
files
  .keys()
  .map((key) =>
    Vue.component(key.split("/").pop().split(".")[0], files(key).default)
  );

// Theme components.
const themeFiles = require.context("./components", true, /\.vue$/i);
themeFiles.keys().map((key) => {
  const componentName = key.split("/").pop().split(".")[0];
  Vue.component(componentName, themeFiles(key).default);
});

// From https://github.com/vuejs/vue/blob/dev/src/core/util/env.js
// Workaround for https://github.com/vuejs/vue/issues/11165
const inBrowser = typeof window !== "undefined";
const UA = inBrowser && window.navigator.userAgent.toLowerCase();
const isEdge = UA && UA.indexOf("edge/") > 0;
const isChrome = UA && /chrome\/\d+/.test(UA) && !isEdge;

if (isChrome) {
  window.addEventListener("pageshow", () => {
    new Vue({
      el: "#app",
    });
  });
} else {
  new Vue({
    el: "#app",
  });
}
