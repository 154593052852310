var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bag" },
    [
      _c("b-table", {
        staticClass: "lt-align-cells-middle",
        attrs: {
          "primary-key": "product_line_id",
          items: _vm.notRemovedBagItems,
          fields: _vm.fields,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(image)",
            fn: function (data) {
              return [
                _c("ws-image", {
                  staticStyle: { "border-radius": "5px" },
                  attrs: {
                    value: data.item.product_line.product,
                    width: 50,
                    height: 50,
                  },
                }),
              ]
            },
          },
          {
            key: "cell(product_line.product.name)",
            fn: function (data) {
              return [
                _c("ws-image", {
                  staticClass: "stacked-image",
                  staticStyle: { "border-radius": "5px" },
                  attrs: {
                    value: data.item.product_line.product,
                    width: 50,
                    height: 50,
                  },
                }),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "/shop/" + data.item.product_line.product.slug,
                      },
                    },
                    [_vm._v(_vm._s(data.value))]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("small", [
                    _vm._v(
                      _vm._s(
                        _vm.$friendlyFormatCurrency(
                          data.item.product_line.price
                        )
                      ) +
                        "\n          " +
                        _vm._s(data.item.product_line.product_measure.name) +
                        "\n        "
                    ),
                  ]),
                ]),
              ]
            },
          },
          {
            key: "cell(quantity)",
            fn: function (data) {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-center" },
                  [
                    _c("fruity-product-quantity-input", {
                      attrs: {
                        "product-line": data.item.product_line,
                        editable: _vm.editable,
                      },
                      model: {
                        value: data.item.quantity,
                        callback: function ($$v) {
                          _vm.$set(data.item, "quantity", $$v)
                        },
                        expression: "data.item.quantity",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "cell(price)",
            fn: function (data) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$friendlyFormatCurrency(
                        data.item.quantity * data.item.product_line.price
                      )
                    ) +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "cell(_actions)",
            fn: function (data) {
              return [
                _c("b-button-close", {
                  on: {
                    click: function ($event) {
                      return _vm.removeBagItem(data.item)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }