<template>
  <fruity-page :basket-quantity="$basketQuantity(bag)" banner-size="none">
    <template #hero-content
      ><div class="fruity-title">Place Order</div></template
    >

    <template #content>
      <b-modal
        id="collection-or-delivery-modal"
        :title="
          fulfilmentMethod === null
            ? `Collection or Delivery`
            : capitalize(fulfilmentMethod)
        "
        :visible="shouldShowCollectionOrDeliveryModal"
        hide-footer
        hide-header-close
        no-close-on-backdrop
        no-close-on-esc
      >
        <template v-if="fulfilmentMethod === null">
          <p>Please choose one of the options.</p>
          <p v-if="deliverySlots.length === 0">
            Sorry, there are no delivery slots available at the moment.
          </p>
          <div class="collection-or-delivery">
            <div class="collection option" @click="collectOrder">
              <div><BIconBag scale="2" icon="bag" /></div>
              <div class="title">Collection</div>
              <div class="body">
                <small>I would like to collect my order from the shop</small>
              </div>
            </div>

            <div
              class="delivery option"
              @click="deliverOrder"
              v-if="deliverySlots.length > 0"
            >
              <div><BIconTruck scale="2" icon="truck" /></div>
              <div class="title">Delivery</div>
              <div class="body">
                <small>I would like to have my order delivered</small>
              </div>
            </div>
          </div>
        </template>

        <div v-if="fulfilmentMethod === 'collection'">
          <p>
            Choose a collection slot. Please order at least two days before
            collection if possible.
          </p>

          <template v-if="collectionSlots.length === 0">
            <b-alert show variant="warning"
              >Sorry, there are no collection slots available at the
              moment.</b-alert
            >
            <b-button @click="fulfilmentMethod = null">Close</b-button>
          </template>

          <div
            v-else
            v-for="collectionSlot in collectionSlots"
            :key="collectionSlot.id"
            class="mb-3"
          >
            <b-button
              @click="chooseFulfilmentSlot(collectionSlot)"
              variant="primary"
              :disabled="collectionSlot.is_full""
              block
            >
              {{ capitalize(formatFulfilmentSlot(collectionSlot)) }}
              {{ collectionSlot.is_full ? " - not available" : ""}}
            </b-button>
          </div>
        </div>

        <div v-if="fulfilmentMethod === 'delivery'">
          <p>
            Choose a delivery slot. See
            <a
              class="link-dark-underline"
              href="https://www.google.com/maps/d/viewer?mid=1QIvTedNP39GWsgwvCVbDrtV8sZMYfSLY&ll=52.466780397135715%2C1.4232594000000098&z=11"
              >our delivery map</a
            >
            for your delivery day. If possible, please order two days in
            advance.
          </p>
          <!--
          <div class="notice mb-3">
            <div class="title">Christmas Deliveries</div>
            On the 22<sup>nd</sup>, 23<sup>rd</sup> and 24<sup>th</sup>
            of December, we will deliver to anywhere within 8 miles of Bungay.
          </div>
              -->
          <template v-if="deliverySlots.length === 0">
            <b-alert show variant="warning"
              >Sorry, there are no delivery slots available at the
              moment.</b-alert
            >
            <b-button @click="fulfilmentMethod = null">Close</b-button>
          </template>
          <div
            v-else
            v-for="deliverySlot in deliverySlots"
            :key="deliverySlot.id"
            class="mb-3"
          >
            <b-button
              @click="chooseFulfilmentSlot(deliverySlot)"
              variant="primary"
              block
            >
              {{ capitalize(formatFulfilmentSlot(deliverySlot)) }}
              - {{ deliverySlot._region }}
            </b-button>
          </div>
        </div>
      </b-modal>

      <div class="place-order mt-5 mb-5" v-if="bag.length > 0">
        <h3 class="mt-5 mb-3">Collection or Delivery</h3>
        <template v-if="fulfilmentMethod === null">
          <b-button
            class="mt-2"
            @click="$bvModal.show('collection-or-delivery')"
            variant="primary"
            >Choose Collection or Delivery</b-button
          >
        </template>
        <template
          v-else-if="
            fulfilmentMethod === 'collection' && selectedCollectionSlot
          "
        >
          <div class="mb-2">
            <b-icon-bag class="mr-2 mb-1" scale="1.5" />
            <template v-if="fulfilmentSlotId === null">
              You've chosen to collect this order.
            </template>
            <template v-else>
              You've chosen to collect this order
              {{
                formatFulfilmentSlot(selectedCollectionSlot, { withOn: true })
              }}.
            </template>

            <div class="mt-2" v-if="deliverySlots.length === 0">
              Sorry, there are no delivery slots available at the moment.
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <div class="m-2">
              <b-button @click="changeCollectionSlot" variant="primary"
                >Change collection slot</b-button
              >
            </div>
            <div class="m-2" v-if="deliverySlots.length > 0">
              <b-button @click="deliverOrder" variant="primary"
                >Request delivery instead</b-button
              >
            </div>
          </div>
        </template>

        <template
          v-else-if="fulfilmentMethod === 'delivery' && selectedDeliverySlot"
        >
          <div>
            <b-icon-truck scale="1.5" class="mr-2 mb-1" />
            You have requested delivery
            {{ formatFulfilmentSlot(selectedDeliverySlot, { withOn: true }) }}.
          </div>

          <div class="mt-2">
            Please see our delivery map for your delivery day and if possible
            please order before 4.30pm two days in advance (e.g. order by 4.30pm
            on Monday if your delivery day is Wednesday).
          </div>

          <div class="d-flex justify-content-center mt-2">
            <div class="m-2">
              <b-button
                variant="primary"
                target="_blank"
                href="https://www.google.com/maps/d/viewer?mid=1QIvTedNP39GWsgwvCVbDrtV8sZMYfSLY&ll=52.466780397135715%2C1.4232594000000098&z=11"
                >Open delivery map</b-button
              >
            </div>
            <div class="m-2">
              <b-button @click="changeDeliverySlot" variant="primary"
                >Change delivery slot</b-button
              >
            </div>
            <div class="m-2">
              <b-button @click="collectOrder" variant="primary"
                >Collect order instead</b-button
              >
            </div>
          </div>
        </template>

        <h3 class="mt-5 mb-3">Your Order</h3>
        <div class="bag" v-if="bag.length > 0">
          <fruity-bag v-model="bag" :editable="true" />
        </div>

        <div class="text-center">
          <h5>
            <div>
              The estimated price for this order is
              {{ $friendlyFormatCurrency(total) }}
            </div>
            <div>
              <small v-if="selectedCollectionSlot"
                >This includes £{{ selectedCollectionSlot._fee }} for
                packing.</small
              >
              <small v-if="selectedDeliverySlot"
                >This includes £{{ selectedDeliverySlot._fee }} for
                delivery.</small
              >
            </div>
            <div class="mt-3">
              <small
                >The final price might be different due to seasonal variations
                in our costs.</small
              >
            </div>
            <div>
              <small>We strive to get you the best price possible. </small>
            </div>
            <div class="mt-3">
              <small>Payment will be taken upon collection.</small>
            </div>
          </h5>
        </div>

        <h3 class="mt-5 mb-3">Your Contact Details</h3>
        <b-form @submit.prevent="onSubmit">
          <div>
            <b-form-group label="Name" label-for="name">
              <b-form-input
                id="name"
                v-model="customerDetails.name"
                @blur="saveCustomerDetails('name')"
                required
              />
            </b-form-group>

            <b-form-group label="Phone number" label-for="phone_number">
              <b-form-input
                id="phone_number"
                v-model="customerDetails.phone_number"
                type="tel"
                @blur="saveCustomerDetails('phone_number')"
                required
              />
            </b-form-group>

            <template v-if="fulfilmentMethod === 'delivery'">
              <b-form-group
                label="House number and street name"
                label-for="address_line1"
              >
                <b-form-input
                  id="address_line1"
                  v-model="customerDetails.address_line1"
                  @blur="saveCustomerDetails('address_line1')"
                  required
                />
              </b-form-group>

              <b-form-group label="Town or city" label-for="city">
                <b-form-input
                  id="city"
                  v-model="customerDetails.city"
                  @blur="saveCustomerDetails('city')"
                  required
                />
              </b-form-group>

              <b-form-group label="Postcode" label-for="postcode">
                <b-form-input
                  id="postcode"
                  v-model="customerDetails.postcode"
                  autocomplete="postal-code"
                  @blur="saveCustomerDetails('postcode')"
                  required
                />
              </b-form-group>

              <b-form-group
                label="Where should we leave your order if you're not in?"
                label-for="delivery-intructions"
              >
                <b-form-input
                  id="delivery-instructions"
                  v-model="customerDetails.deliveryInstructions"
                  @blur="saveCustomerDetails('deliveryInstructions')"
                  required
                />
              </b-form-group>
            </template>

            <b-form-group
              label="Please add any additional notes for the shop regarding your order"
              label-for="customer-order-notes"
            >
              <b-form-textarea
                id="customer-order-notes"
                v-model="customerOrderNotes"
                maxlength="255"
                rows="2"
                max-rows="5"
              />
              <b-form-invalid-feedback :state="customerOrderNotes.length <= 254"
                >The maximum length is 255 characters</b-form-invalid-feedback
              >
            </b-form-group>

            <!-- label="Email address - (optional) we will send you an order confirmation" -->
            <b-form-group
              label="Email address - (optional) we may wish to email you about your order"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="customerDetails.email"
                @blur="
                  saveCustomerDetails('email');
                  finishedTypingEmail = true;
                "
              />
              <b-form-invalid-feedback :state="validEmail"
                >Sorry, that doesn't look like a valid email
                address.</b-form-invalid-feedback
              >
            </b-form-group>

            <b-form-group
              label="Can we send you emails from time to time about our produce, offers, etc?"
              label-for="marketing-consent"
            >
              <b-form-checkbox
                id="marketing-consent"
                v-model="customerDetails.marketingConsent"
                @blur="saveCustomerDetails('marketingConsent')"
                >Yes, you can send me emails.
              </b-form-checkbox>
            </b-form-group>
          </div>
          <b-button
            variant="primary"
            type="submit"
            :disabled="isSubmitting"
            block
            >Place Order
            <BSpinner v-if="isSubmitting" small />
          </b-button>
        </b-form>
      </div>
      <div class="empty-bag mt-5 mb-5 text-center" v-else>
        <p>You don't have anything in your shopping basket.</p>
        <p>To add some items, click the link below.</p>
        <p>
          <b-button variant="secondary" href="/shop">Shop Now</b-button>
        </p>
      </div>
    </template>
  </fruity-page>
</template>

<script>
import { get, sumBy, clone } from "lodash";
import { format, parseISO, isToday, isTomorrow, isYesterday } from "date-fns";
import * as EmailValidator from "email-validator";

import {
  BAlert,
  BModal,
  BIconTruck,
  BIconBag,
  BButton,
  BForm,
  BFormInvalidFeedback,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";

export default {
  props: {
    initialBag: {
      required: true,
      type: Array,
    },
    initialCollectionOrDelivery: {
      required: false,
      default: () => null,
    },
    initialFulfilmentSlotId: {
      required: true,
    },
    productLines: {
      required: true,
      type: Array,
    },
    collectionSlots: {
      required: true,
      type: Array,
    },
    deliverySlots: {
      required: true,
      type: Array,
    },
    initialCustomerDetails: {
      required: false,
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    const fulfilmentSlots = [...this.collectionSlots, ...this.deliverySlots];

    return {
      isSubmitting: false,
      bag: this.initialBag.map((bagItem) => ({
        ...bagItem,
        product_line: this.productLines.find(
          (pl) => pl.id === bagItem.product_line_id
        ),
      })),
      fulfilmentMethod: clone(this.initialCollectionOrDelivery),
      fulfilmentSlotId: fulfilmentSlots.find(
        (collectionSlot) => collectionSlot.id === this.initialFulfilmentSlotId
      )
        ? clone(this.initialFulfilmentSlotId)
        : null,
      customerOrderNotes: "",
      customerDetails: {
        name: get(this.initialCustomerDetails, "name", ""),
        phone_number: get(this.initialCustomerDetails, "phone_number", ""),
        address_line1: get(this.initialCustomerDetails, "address_line1", ""),
        city: get(this.initialCustomerDetails, "city", ""),
        postcode: get(this.initialCustomerDetails, "postcode", ""),
        email: get(this.initialCustomerDetails, "email", ""),
        deliveryInstructions: get(
          this.initialCustomerDetails,
          "deliveryInstructions",
          ""
        ),
      },
      finishedTypingEmail: false,
    };
  },
  computed: {
    total() {
      return (
        sumBy(
          this.bag,
          (bagItem) => bagItem.quantity * bagItem.product_line.price
        ) + get(this.selectedFulfilmentSlot, "_fee", 0)
      );
    },
    selectedCollectionSlot() {
      return this.collectionSlots.find((cs) => cs.id === this.fulfilmentSlotId);
    },
    selectedDeliverySlot() {
      return (
        this.deliverySlots.find(
          (deliverySlot) => deliverySlot.id === this.fulfilmentSlotId
        ) || null
      );
    },
    selectedFulfilmentSlot() {
      if (this.fulfilmentMethod === "collection") {
        return this.selectedCollectionSlot;
      }

      if (this.fulfilmentMethod === "delivery") {
        return this.selectedDeliverySlot;
      }

      return null;
    },
    selectedDeliveryRegion() {
      if (this.selectedDeliverySlot === null) {
        return null;
      }

      return this.selectedDeliverySlot._region;
    },
    shouldShowCollectionOrDeliveryModal() {
      return this.fulfilmentMethod === null || this.fulfilmentSlotId === null;
    },
    validEmail() {
      return (
        this.customerDetails.email.length === 0 ||
        this.finishedTypingEmail === false ||
        EmailValidator.validate(this.customerDetails.email)
      );
    },
  },
  watch: {
    fulfilmentMethod(newVal) {
      this.$api.put("/collection-or-delivery", { fulfilmentMethod: newVal });
    },
    fulfilmentSlotId(newVal) {
      this.$api.put("/collection-slot-id", { fulfilmentSlotId: newVal });
    },
  },
  methods: {
    formatFulfilmentSlot(collectionSlot, options = { withOn: false }) {
      const collectionSlotStartTime = parseISO(collectionSlot.starts_at);
      const collectionSlotEndTime = parseISO(collectionSlot.ends_at);

      let str = "";
      if (isToday(collectionSlotStartTime)) {
        str += "today";
      } else if (isTomorrow(collectionSlotStartTime)) {
        str += "tomorrow";
      } else if (isYesterday(collectionSlotStartTime)) {
        str += "yesterday";
      } else {
        if (options.withOn === true) {
          str += "on ";
        }

        str += format(collectionSlotStartTime, "EEEE do MMMM");
      }

      str +=
        " from " +
        format(collectionSlotStartTime, "h.mmaaaaa'm'") +
        " to " +
        format(collectionSlotEndTime, "h.mmaaaaa'm'");

      return str;
    },
    collectOrder() {
      this.fulfilmentMethod = "collection";
      this.fulfilmentSlotId = null;
    },
    deliverOrder() {
      this.fulfilmentMethod = "delivery";
      this.fulfilmentSlotId = null;
    },
    chooseFulfilmentSlot(fulfilmentSlot) {
      this.fulfilmentSlotId = fulfilmentSlot.id;
    },
    changeCollectionSlot() {
      this.fulfilmentSlotId = null;
    },
    changeDeliverySlot() {
      this.fulfilmentSlotId = null;
    },
    // eslint-disable-next-line no-unused-vars
    onSubmit(event) {
      if (EmailValidator.validate(this.customerDetails.email) === false) {
        alert(
          "Sorry, that doesn't look like a valid email address. Please change it and try again."
        );
        return;
      }

      this.isSubmitting = true;

      this.$api
        .post("place-order", {
          customerDetails: this.customerDetails,
          fulfilmentMethod: this.fulfilmentMethod,
          fulfilmentSlotId: this.fulfilmentSlotId,
          customerOrderNotes: this.customerOrderNotes,
          // Do not submit the product data, it can result in "request entity too large"
          bag: this.bag.map((bagItem) => ({
            product_line_id: bagItem.product_line_id,
            quantity: bagItem.quantity,
          })),
        })
        .then((r) => (window.location = r.data.redirect))
        // eslint-disable-next-line no-unused-vars
        .catch((err) => {
          alert("Sorry, something went wrong.");
          this.isSubmitting = false;
        });
    },
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    saveCustomerDetails(field) {
      this.$api.put("/customer-details", {
        [field]: this.customerDetails[field],
      });
    },
  },
  components: {
    BAlert,
    BModal,
    BIconTruck,
    BIconBag,
    BButton,
    BForm,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    BSpinner,
  },
  created: function () {},
  mounted: function () {},
};
</script>

<style lang="scss" scoped>
@import "../_variables.scss";

.place-order,
.empty-bag {
  max-width: 800px;
  margin: auto;

  & > * {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media only screen and (max-width: 500px) {
    & > .bag {
      /** Remove the container padding on small screens */
      padding-left: 0px;
      padding-right: 0px;
    }

    ::v-deep .table th,
    ::v-deep .table td {
      padding: 0.25rem;
    }
  }

  @media only screen and (max-width: 350px) {
    & > .bag {
      font-size: 14px;
    }
  }

  ::v-deep .image-cell {
    width: 120px;
  }

  .quantity-input-group {
    margin: auto;
    width: 130px;
  }
}

.collection-or-delivery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -5px;

  .option {
    margin: 5px;
    flex: 1;
    cursor: pointer;
    width: 45%;
    min-width: 140px;
    border-radius: 10px;
    border: 3px solid #222;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .body {
      font-size: 1.2rem;
      line-height: 1.2rem;
    }

    text-align: center;

    &.collection {
      background-color: $fruity-yellow;
    }

    &.delivery {
      background-color: $fruity-yellow;
    }

    .title {
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
    }
  }
}

div.notice {
  .title {
    text-decoration: underline;
  }

  text-align: center;
  border: 2px dashed #cc3333;
  padding: 5px;
}
</style>
