import { render, staticRenderFns } from "./ws-menu-product-card.vue?vue&type=template&id=3d579d83&scoped=true&"
import script from "./ws-menu-product-card.vue?vue&type=script&lang=js&"
export * from "./ws-menu-product-card.vue?vue&type=script&lang=js&"
import style0 from "./ws-menu-product-card.vue?vue&type=style&index=0&id=3d579d83&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d579d83",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jamespickard/Code/lt/SITES/giddensandthompson-web-shop/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3d579d83')) {
      api.createRecord('3d579d83', component.options)
    } else {
      api.reload('3d579d83', component.options)
    }
    module.hot.accept("./ws-menu-product-card.vue?vue&type=template&id=3d579d83&scoped=true&", function () {
      api.rerender('3d579d83', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/generic/components/ws-menu-product-card.vue"
export default component.exports