var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("fruity-page", {
    scopedSlots: _vm._u([
      {
        key: "hero-content",
        fn: function () {
          return [
            _c("div", { staticClass: "fruity-title" }, [_vm._v("Thank You!")]),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c("div", { staticClass: "thank-you mt-5 mb-5" }, [
              _c("div", { staticClass: "order-confirmation" }, [
                _c("div", [_vm._v("Your order has been placed.")]),
                _vm._v(" "),
                _c("div", [_vm._v("For reference, your order number is")]),
                _vm._v(" "),
                _c("div", { staticClass: "order-number" }, [
                  _vm._v("#" + _vm._s(_vm.customerOrderId)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "order-review" }, [
                _c(
                  "div",
                  [
                    _c("fruity-bag", {
                      attrs: { value: _vm.bag, editable: false },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "text-center" }, [
                  _vm._v(
                    "\n          If you need to change your order or have any questions please call\n          the shop on "
                  ),
                  _c("a", { attrs: { href: "tel:01986897944" } }, [
                    _vm._v("01986 897944"),
                  ]),
                  _vm._v(".\n        "),
                ]),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }