var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("fruity-page", {
    attrs: {
      "basket-quantity": _vm.$basketQuantity(_vm.bag),
      "banner-size": "none",
    },
    scopedSlots: _vm._u([
      {
        key: "hero-content",
        fn: function () {
          return [
            _c("div", { staticClass: "fruity-title" }, [_vm._v("Basket")]),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _vm.reorderNotification
              ? _c("div", { staticClass: "notice mt-5" }, [
                  _vm._v(
                    "\n      " + _vm._s(_vm.reorderNotification) + "\n    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.bag.length > 0
              ? _c("div", { staticClass: "basket mt-5 mb-5" }, [
                  _vm.shouldShowFilters
                    ? _c(
                        "div",
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: { placeholder: "Search..." },
                                model: {
                                  value: _vm.filterText,
                                  callback: function ($$v) {
                                    _vm.filterText = $$v
                                  },
                                  expression: "filterText",
                                },
                              }),
                              _vm._v(" "),
                              _c("b-form-select", {
                                attrs: { options: _vm.categoryOptions },
                                model: {
                                  value: _vm.filterCategory,
                                  callback: function ($$v) {
                                    _vm.filterCategory = $$v
                                  },
                                  expression: "filterCategory",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h3", { staticClass: "mt-5 mb-3" }, [
                    _vm._v("Your Order"),
                  ]),
                  _vm._v(" "),
                  _vm.bag.length > 0
                    ? _c(
                        "div",
                        { staticClass: "bag" },
                        [
                          _c("fruity-bag", {
                            attrs: { editable: true },
                            model: {
                              value: _vm.filteredBag,
                              callback: function ($$v) {
                                _vm.filteredBag = $$v
                              },
                              expression: "filteredBag",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _c("div", { staticClass: "empty-bag mt-5 mb-5 text-center" }, [
                  _c("p", [
                    _vm._v("You don't have anything in your shopping basket."),
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v("To add some items, click the link below.")]),
                  _vm._v(" "),
                  _c(
                    "p",
                    [
                      _c(
                        "b-button",
                        { attrs: { variant: "secondary", href: "/shop" } },
                        [_vm._v("Shop Now")]
                      ),
                    ],
                    1
                  ),
                ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex justify-content-center mt-2 mb-3" },
              [
                _c(
                  "div",
                  { staticClass: "action-button m-2" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { href: "/shop", variant: "primary", block: "" },
                      },
                      [_vm._v("Continue shopping")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "action-button m-2" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: {
                          href: "/place-order",
                          variant: "secondary",
                          block: "",
                        },
                      },
                      [_vm._v("Go to checkout")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }