var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "products" },
    [
      _c(
        "b-container",
        [
          _c(
            "b-input-group",
            [
              _c("b-form-input", {
                attrs: { placeholder: "Search..." },
                model: {
                  value: _vm.filterText,
                  callback: function ($$v) {
                    _vm.filterText = $$v
                  },
                  expression: "filterText",
                },
              }),
              _vm._v(" "),
              _c("b-form-select", {
                attrs: { options: _vm.categoryOptions },
                model: {
                  value: _vm.filterCategory,
                  callback: function ($$v) {
                    _vm.filterCategory = $$v
                  },
                  expression: "filterCategory",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.filterTitle !== null
            ? _c("div", { staticClass: "mt-1 mb-1 text-center" }, [
                _c("h1", [_vm._v(_vm._s(_vm.filterTitle))]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "product-cards" },
            [
              _vm._l(_vm.filteredProducts, function (product) {
                return [
                  _c("fruity-product-card", {
                    key: product.id,
                    attrs: {
                      href: _vm.productUrl(product),
                      title: product.name,
                      "image-url": product.image_url,
                      "image-srcset": product.image_srcset,
                    },
                  }),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }