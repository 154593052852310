import { render, staticRenderFns } from "./place-order.vue?vue&type=template&id=2f95e320&scoped=true&"
import script from "./place-order.vue?vue&type=script&lang=js&"
export * from "./place-order.vue?vue&type=script&lang=js&"
import style0 from "./place-order.vue?vue&type=style&index=0&id=2f95e320&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f95e320",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jamespickard/Code/lt/SITES/giddensandthompson-web-shop/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2f95e320')) {
      api.createRecord('2f95e320', component.options)
    } else {
      api.reload('2f95e320', component.options)
    }
    module.hot.accept("./place-order.vue?vue&type=template&id=2f95e320&scoped=true&", function () {
      api.rerender('2f95e320', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/fruity/components/place-order.vue"
export default component.exports