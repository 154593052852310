<template>
  <fruity-page :basket-quantity="$basketQuantity(bag)" banner-size="none">
    <template #hero-content
      ><div class="gandt-title">Privacy Policy</div></template
    >
    <template #content>
      <div class="privacy-policy" v-html="privacyPolicyHtml" />
    </template>
  </fruity-page>
</template>

<script>
import { Converter } from "showdown";

export default {
  props: {
    bag: {
      required: true,
      type: Array,
    },
    privacyPolicyMarkdown: {
      required: true,
      type: String,
    },
  },
  data: function () {
    return {};
  },
  computed: {
    converter() {
      return new Converter();
    },
    privacyPolicyHtml() {
      return this.converter.makeHtml(this.privacyPolicyMarkdown);
    },
  },
  watch: {},
  methods: {},
  components: {},
  created: function () {},
  mounted: function () {},
};
</script>

<style lang="scss" scoped>
.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 1em;

  /* Make the styles a little bit smaller */
  ::v-deep h1 {
    font-size: 2em;
    margin-top: 1em;
  }

  ::v-deep h2 {
    font-size: 1.75em;
  }

  ::v-deep h3 {
    font-size: 1.5;
  }

  ::v-deep h4 {
    font-size: 1.25;
  }

  ::v-deep h5 {
    font-size: 1.1;
    font-weight: bold;
  }
}
</style>
