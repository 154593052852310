var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("fruity-page", {
    scopedSlots: _vm._u([
      {
        key: "hero-content",
        fn: function () {
          return [
            _c("div", { staticClass: "fruity-title" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c("div", { staticClass: "error-page mt-5 mb-5" }, [
              _vm._v("\n      " + _vm._s(_vm.text) + "\n    "),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }