var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("fruity-page", {
    attrs: {
      "basket-quantity": _vm.$basketQuantity(_vm.bag),
      "banner-size": "none",
    },
    scopedSlots: _vm._u([
      {
        key: "hero-content",
        fn: function () {
          return [
            _c("div", { staticClass: "fruity-title" }, [_vm._v("Place Order")]),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "b-modal",
              {
                attrs: {
                  id: "collection-or-delivery-modal",
                  title:
                    _vm.fulfilmentMethod === null
                      ? "Collection or Delivery"
                      : _vm.capitalize(_vm.fulfilmentMethod),
                  visible: _vm.shouldShowCollectionOrDeliveryModal,
                  "hide-footer": "",
                  "hide-header-close": "",
                  "no-close-on-backdrop": "",
                  "no-close-on-esc": "",
                },
              },
              [
                _vm.fulfilmentMethod === null
                  ? [
                      _c("p", [_vm._v("Please choose one of the options.")]),
                      _vm._v(" "),
                      _vm.deliverySlots.length === 0
                        ? _c("p", [
                            _vm._v(
                              "\n          Sorry, there are no delivery slots available at the moment.\n        "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "collection-or-delivery" }, [
                        _c(
                          "div",
                          {
                            staticClass: "collection option",
                            on: { click: _vm.collectOrder },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("BIconBag", {
                                  attrs: { scale: "2", icon: "bag" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "title" }, [
                              _vm._v("Collection"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "body" }, [
                              _c("small", [
                                _vm._v(
                                  "I would like to collect my order from the shop"
                                ),
                              ]),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.deliverySlots.length > 0
                          ? _c(
                              "div",
                              {
                                staticClass: "delivery option",
                                on: { click: _vm.deliverOrder },
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("BIconTruck", {
                                      attrs: { scale: "2", icon: "truck" },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("Delivery"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "body" }, [
                                  _c("small", [
                                    _vm._v(
                                      "I would like to have my order delivered"
                                    ),
                                  ]),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.fulfilmentMethod === "collection"
                  ? _c(
                      "div",
                      [
                        _c("p", [
                          _vm._v(
                            "\n          Choose a collection slot. Please order at least two days before\n          collection if possible.\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.collectionSlots.length === 0
                          ? [
                              _c(
                                "b-alert",
                                { attrs: { show: "", variant: "warning" } },
                                [
                                  _vm._v(
                                    "Sorry, there are no collection slots available at the\n            moment."
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.fulfilmentMethod = null
                                    },
                                  },
                                },
                                [_vm._v("Close")]
                              ),
                            ]
                          : _vm._l(
                              _vm.collectionSlots,
                              function (collectionSlot) {
                                return _c(
                                  "div",
                                  {
                                    key: collectionSlot.id,
                                    staticClass: "mb-3",
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          variant: "primary",
                                          disabled: collectionSlot.is_full,
                                          block: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.chooseFulfilmentSlot(
                                              collectionSlot
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.capitalize(
                                                _vm.formatFulfilmentSlot(
                                                  collectionSlot
                                                )
                                              )
                                            ) +
                                            "\n            " +
                                            _vm._s(
                                              collectionSlot.is_full
                                                ? " - not available"
                                                : ""
                                            ) +
                                            "\n          "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.fulfilmentMethod === "delivery"
                  ? _c(
                      "div",
                      [
                        _c("p", [
                          _vm._v(
                            "\n          Choose a delivery slot. See\n          "
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "link-dark-underline",
                              attrs: {
                                href: "https://www.google.com/maps/d/viewer?mid=1QIvTedNP39GWsgwvCVbDrtV8sZMYfSLY&ll=52.466780397135715%2C1.4232594000000098&z=11",
                              },
                            },
                            [_vm._v("our delivery map")]
                          ),
                          _vm._v(
                            "\n          for your delivery day. If possible, please order two days in\n          advance.\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.deliverySlots.length === 0
                          ? [
                              _c(
                                "b-alert",
                                { attrs: { show: "", variant: "warning" } },
                                [
                                  _vm._v(
                                    "Sorry, there are no delivery slots available at the\n            moment."
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.fulfilmentMethod = null
                                    },
                                  },
                                },
                                [_vm._v("Close")]
                              ),
                            ]
                          : _vm._l(_vm.deliverySlots, function (deliverySlot) {
                              return _c(
                                "div",
                                { key: deliverySlot.id, staticClass: "mb-3" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "primary", block: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.chooseFulfilmentSlot(
                                            deliverySlot
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.capitalize(
                                              _vm.formatFulfilmentSlot(
                                                deliverySlot
                                              )
                                            )
                                          ) +
                                          "\n            - " +
                                          _vm._s(deliverySlot._region) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _vm.bag.length > 0
              ? _c(
                  "div",
                  { staticClass: "place-order mt-5 mb-5" },
                  [
                    _c("h3", { staticClass: "mt-5 mb-3" }, [
                      _vm._v("Collection or Delivery"),
                    ]),
                    _vm._v(" "),
                    _vm.fulfilmentMethod === null
                      ? [
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-2",
                              attrs: { variant: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.show(
                                    "collection-or-delivery"
                                  )
                                },
                              },
                            },
                            [_vm._v("Choose Collection or Delivery")]
                          ),
                        ]
                      : _vm.fulfilmentMethod === "collection" &&
                        _vm.selectedCollectionSlot
                      ? [
                          _c(
                            "div",
                            { staticClass: "mb-2" },
                            [
                              _c("b-icon-bag", {
                                staticClass: "mr-2 mb-1",
                                attrs: { scale: "1.5" },
                              }),
                              _vm._v(" "),
                              _vm.fulfilmentSlotId === null
                                ? [
                                    _vm._v(
                                      "\n            You've chosen to collect this order.\n          "
                                    ),
                                  ]
                                : [
                                    _vm._v(
                                      "\n            You've chosen to collect this order\n            " +
                                        _vm._s(
                                          _vm.formatFulfilmentSlot(
                                            _vm.selectedCollectionSlot,
                                            { withOn: true }
                                          )
                                        ) +
                                        ".\n          "
                                    ),
                                  ],
                              _vm._v(" "),
                              _vm.deliverySlots.length === 0
                                ? _c("div", { staticClass: "mt-2" }, [
                                    _vm._v(
                                      "\n            Sorry, there are no delivery slots available at the moment.\n          "
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-center" },
                            [
                              _c(
                                "div",
                                { staticClass: "m-2" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "primary" },
                                      on: { click: _vm.changeCollectionSlot },
                                    },
                                    [_vm._v("Change collection slot")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.deliverySlots.length > 0
                                ? _c(
                                    "div",
                                    { staticClass: "m-2" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "primary" },
                                          on: { click: _vm.deliverOrder },
                                        },
                                        [_vm._v("Request delivery instead")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      : _vm.fulfilmentMethod === "delivery" &&
                        _vm.selectedDeliverySlot
                      ? [
                          _c(
                            "div",
                            [
                              _c("b-icon-truck", {
                                staticClass: "mr-2 mb-1",
                                attrs: { scale: "1.5" },
                              }),
                              _vm._v(
                                "\n          You have requested delivery\n          " +
                                  _vm._s(
                                    _vm.formatFulfilmentSlot(
                                      _vm.selectedDeliverySlot,
                                      { withOn: true }
                                    )
                                  ) +
                                  ".\n        "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "mt-2" }, [
                            _vm._v(
                              "\n          Please see our delivery map for your delivery day and if possible\n          please order before 4.30pm two days in advance (e.g. order by 4.30pm\n          on Monday if your delivery day is Wednesday).\n        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "d-flex justify-content-center mt-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "m-2" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        variant: "primary",
                                        target: "_blank",
                                        href: "https://www.google.com/maps/d/viewer?mid=1QIvTedNP39GWsgwvCVbDrtV8sZMYfSLY&ll=52.466780397135715%2C1.4232594000000098&z=11",
                                      },
                                    },
                                    [_vm._v("Open delivery map")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "m-2" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "primary" },
                                      on: { click: _vm.changeDeliverySlot },
                                    },
                                    [_vm._v("Change delivery slot")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "m-2" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "primary" },
                                      on: { click: _vm.collectOrder },
                                    },
                                    [_vm._v("Collect order instead")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _c("h3", { staticClass: "mt-5 mb-3" }, [
                      _vm._v("Your Order"),
                    ]),
                    _vm._v(" "),
                    _vm.bag.length > 0
                      ? _c(
                          "div",
                          { staticClass: "bag" },
                          [
                            _c("fruity-bag", {
                              attrs: { editable: true },
                              model: {
                                value: _vm.bag,
                                callback: function ($$v) {
                                  _vm.bag = $$v
                                },
                                expression: "bag",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-center" }, [
                      _c("h5", [
                        _c("div", [
                          _vm._v(
                            "\n            The estimated price for this order is\n            " +
                              _vm._s(_vm.$friendlyFormatCurrency(_vm.total)) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _vm.selectedCollectionSlot
                            ? _c("small", [
                                _vm._v(
                                  "This includes £" +
                                    _vm._s(_vm.selectedCollectionSlot._fee) +
                                    " for\n              packing."
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.selectedDeliverySlot
                            ? _c("small", [
                                _vm._v(
                                  "This includes £" +
                                    _vm._s(_vm.selectedDeliverySlot._fee) +
                                    " for\n              delivery."
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "mt-3" }, [
                          _c("small", [
                            _vm._v(
                              "The final price might be different due to seasonal variations\n              in our costs."
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("small", [
                            _vm._v(
                              "We strive to get you the best price possible. "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "mt-3" }, [
                          _c("small", [
                            _vm._v("Payment will be taken upon collection."),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("h3", { staticClass: "mt-5 mb-3" }, [
                      _vm._v("Your Contact Details"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "b-form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.onSubmit.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "b-form-group",
                              { attrs: { label: "Name", "label-for": "name" } },
                              [
                                _c("b-form-input", {
                                  attrs: { id: "name", required: "" },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.saveCustomerDetails("name")
                                    },
                                  },
                                  model: {
                                    value: _vm.customerDetails.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.customerDetails, "name", $$v)
                                    },
                                    expression: "customerDetails.name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Phone number",
                                  "label-for": "phone_number",
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "phone_number",
                                    type: "tel",
                                    required: "",
                                  },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.saveCustomerDetails(
                                        "phone_number"
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.customerDetails.phone_number,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.customerDetails,
                                        "phone_number",
                                        $$v
                                      )
                                    },
                                    expression: "customerDetails.phone_number",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.fulfilmentMethod === "delivery"
                              ? [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "House number and street name",
                                        "label-for": "address_line1",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "address_line1",
                                          required: "",
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.saveCustomerDetails(
                                              "address_line1"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.customerDetails.address_line1,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.customerDetails,
                                              "address_line1",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "customerDetails.address_line1",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Town or city",
                                        "label-for": "city",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: { id: "city", required: "" },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.saveCustomerDetails(
                                              "city"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.customerDetails.city,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.customerDetails,
                                              "city",
                                              $$v
                                            )
                                          },
                                          expression: "customerDetails.city",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: "Postcode",
                                        "label-for": "postcode",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "postcode",
                                          autocomplete: "postal-code",
                                          required: "",
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.saveCustomerDetails(
                                              "postcode"
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.customerDetails.postcode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.customerDetails,
                                              "postcode",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "customerDetails.postcode",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label:
                                          "Where should we leave your order if you're not in?",
                                        "label-for": "delivery-intructions",
                                      },
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "delivery-instructions",
                                          required: "",
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.saveCustomerDetails(
                                              "deliveryInstructions"
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.customerDetails
                                              .deliveryInstructions,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.customerDetails,
                                              "deliveryInstructions",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "customerDetails.deliveryInstructions",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label:
                                    "Please add any additional notes for the shop regarding your order",
                                  "label-for": "customer-order-notes",
                                },
                              },
                              [
                                _c("b-form-textarea", {
                                  attrs: {
                                    id: "customer-order-notes",
                                    maxlength: "255",
                                    rows: "2",
                                    "max-rows": "5",
                                  },
                                  model: {
                                    value: _vm.customerOrderNotes,
                                    callback: function ($$v) {
                                      _vm.customerOrderNotes = $$v
                                    },
                                    expression: "customerOrderNotes",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "b-form-invalid-feedback",
                                  {
                                    attrs: {
                                      state:
                                        _vm.customerOrderNotes.length <= 254,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "The maximum length is 255 characters"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label:
                                    "Email address - (optional) we may wish to email you about your order",
                                  "label-for": "email",
                                },
                              },
                              [
                                _c("b-form-input", {
                                  attrs: { id: "email" },
                                  on: {
                                    blur: function ($event) {
                                      _vm.saveCustomerDetails("email")
                                      _vm.finishedTypingEmail = true
                                    },
                                  },
                                  model: {
                                    value: _vm.customerDetails.email,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.customerDetails,
                                        "email",
                                        $$v
                                      )
                                    },
                                    expression: "customerDetails.email",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "b-form-invalid-feedback",
                                  { attrs: { state: _vm.validEmail } },
                                  [
                                    _vm._v(
                                      "Sorry, that doesn't look like a valid email\n              address."
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label:
                                    "Can we send you emails from time to time about our produce, offers, etc?",
                                  "label-for": "marketing-consent",
                                },
                              },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    attrs: { id: "marketing-consent" },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.saveCustomerDetails(
                                          "marketingConsent"
                                        )
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.customerDetails.marketingConsent,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.customerDetails,
                                          "marketingConsent",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "customerDetails.marketingConsent",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "Yes, you can send me emails.\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            attrs: {
                              variant: "primary",
                              type: "submit",
                              disabled: _vm.isSubmitting,
                              block: "",
                            },
                          },
                          [
                            _vm._v("Place Order\n          "),
                            _vm.isSubmitting
                              ? _c("BSpinner", { attrs: { small: "" } })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                )
              : _c("div", { staticClass: "empty-bag mt-5 mb-5 text-center" }, [
                  _c("p", [
                    _vm._v("You don't have anything in your shopping basket."),
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v("To add some items, click the link below.")]),
                  _vm._v(" "),
                  _c(
                    "p",
                    [
                      _c(
                        "b-button",
                        { attrs: { variant: "secondary", href: "/shop" } },
                        [_vm._v("Shop Now")]
                      ),
                    ],
                    1
                  ),
                ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }